import React from "react";
import classes from "./InfoRow.css"


const infoRow = (props) => {

  const outerClasses = [classes.rowContainer];
  if (props.firstRow === true) {
    outerClasses.push(classes.isFirstRow);
  }

  // Apply color styling to the body
  let bodyStyling = {}
  switch (props.success) {
    case true:
      bodyStyling = {color: "#35BB8A"};
      break;

    case false:
      bodyStyling = {color: "#E74C3C"};
      break;

    default: break;
  }

  return (
    <div className={outerClasses.join(" ")}>
      <p className={classes.title}>{props.title}</p>
      <p className={classes.body} style={bodyStyling}>{props.body}</p>
    </div>
  );
};


export default infoRow;
