import React, { Component } from "react";
import axios from "../../../axios";
import classes from "./financial.css"
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import { calculateStock } from "../../3-stock/1-overview/stockCalculator.js"


const customerColumns = [
  { name: "Klant", selector: "customerName", sortable: true, style: { fontSize: "15px" }},
  { name: "Totale omzet", selector: "revenue", format: row => row.revenue ? "€ " + row.revenue.toLocaleString("de-DE") : "", sortable: true, left: true, style: { fontSize: "14px" }},
]
const yearColumns = [
  { name: "Boekjaar", selector: "financialYear", sortable: true, style: { fontSize: "15px" }},
  { name: "Totale omzet", selector: "revenue", format: row => row.revenue ? "€ " + row.revenue.toLocaleString("de-DE") : "", sortable: true, left: true, style: { fontSize: "14px" }},
]

class Financial extends Component {

  state = {
    revenuePerYear: [],
    revenuePerCustomer: [],
    currentRevenue: null,
    currentPurchases: null,
    currentMargin: null,
    currentStockValue: null,

    // Rekeningstand
    currentBalance: "",
    supplierCredit: "",
    toBeInvoiced: "",
  }


  componentDidMount() {

    // Fetch financial overview
    axios.get("/financial/overview").then(response => {
      if (response.data) {
        this.setState({ ...response.data })
      }
    })

    // Calculate stock value meanwhile
    axios.get("/parts/all").then(response => {
      if (response.data) {
        let parts = response.data

        axios.get("/bookings/all").then(response => {
          if (response.data) {
            let bookings = response.data
            let stock = calculateStock(parts, bookings)

            this.setState({ currentStockValue: stock.stats.totalValue })
          }
        })
      }
    })
  }

  render() {

    // Calculate balance
    let netBalance = 0
    let currentBalance = (this.state.currentBalance === "") ? 0 : parseInt(this.state.currentBalance)
    let supplierCredit = (this.state.supplierCredit === "") ? 0 : parseInt(this.state.supplierCredit)
    let toBeInvoiced = (this.state.toBeInvoiced === "") ? 0 : parseInt(this.state.toBeInvoiced)

    if (!isNaN(currentBalance) && !isNaN(supplierCredit) && !isNaN(toBeInvoiced)) {
      if (currentBalance > 0 || supplierCredit > 0 || toBeInvoiced > 0) {
        netBalance = currentBalance - supplierCredit + this.state.customerCredit + toBeInvoiced
      }
    }

    return (
      <ContentContainer title="Financieel">

        <p className={classes.subtitle}>Overzicht</p>

        <div className={classes.highlightContainer}>
          <div className={classes.highlightTile}>
            <label>{this.state.currentRevenue ? "€ " + this.state.currentRevenue.toLocaleString("de-DE") : ""}</label>
            <label>Omzet {new Date().getFullYear()}</label>
          </div>
          <div className={classes.highlightTile}>
            <label>{this.state.currentPurchases ? "€ " + this.state.currentPurchases.toLocaleString("de-DE") : ""}</label>
            <label>Aankopen {new Date().getFullYear()}</label>
          </div>
          <div className={classes.highlightTile}>
            <label>{this.state.currentMargin ? "€ " + this.state.currentMargin.toLocaleString("de-DE") : ""}</label>
            <label>Marge {new Date().getFullYear()}</label>
          </div>
        </div>

        <div className={classes.highlightContainer}>
          <div className={classes.highlightTile}>
            <label>{this.state.customerCredit ? "€ " + Math.round(this.state.customerCredit).toLocaleString("de-DE") : ""}</label>
            <label>Klantkredieten</label>
          </div>
          <div className={classes.highlightTile}>
            <label>{this.state.currentStockValue ? "€ " + Math.round(this.state.currentStockValue).toLocaleString("de-DE") : ""}</label>
            <label>Waardering stock</label>
          </div>
        </div>

        <div className={classes.groupContainer}>
          <div className={classes.groupDialog}>
          <p className={classes.subtitle}>Omzet per klant</p>
            <div className={classes.tableContainer}>
              <DataTable
                columns={customerColumns}
                data={this.state.revenuePerCustomer}
                noHeader={true}
                pagination={false}
                highlightOnHover={true}
                striped={true}
                defaultSortField={"revenue"}
                defaultSortAsc={false}
                customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}
                onRowClicked={(invoice) => this.displayModal(true, invoice)}
              />
              </div>
          </div>
          <div className={classes.groupDialog}>
          <p className={classes.subtitle}>Omzet per boekjaar</p>
            <div className={classes.tableContainer}>
              <DataTable
                columns={yearColumns}
                data={this.state.revenuePerYear}
                noHeader={true}
                highlightOnHover={true}
                striped={true}
                defaultSortField={"financialYear"}
                defaultSortAsc={false}
                customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}
                onRowClicked={(invoice) => this.displayModal(true, invoice)}
              />
            </div>
          </div>
        </div>

        <p className={classes.subtitle}>Rekeningstand</p>
        <table className={classes.overviewTable}>
          <tbody>
            <tr>
              <td>Huidige stand</td>
              <td><input type="text" value={this.state.currentBalance} placeholder={"Bedrag op de rekening"} onChange={(event) => this.setState({ currentBalance: event.target.value })}/></td>
            </tr>
            <tr>
              <td>Leverancierskredieten</td>
              <td><input type="text" value={this.state.supplierCredit} placeholder={"Betalingen ingepland"} onChange={(event) => this.setState({ supplierCredit: event.target.value })}/></td>
            </tr>
            <tr>
              <td>Klantkredieten</td>
              <td><label>{this.state.customerCredit ? "€ " + Math.round(this.state.customerCredit).toLocaleString("de-DE") : "-"}</label></td>
            </tr>
            <tr>
              <td>Nog te factureren</td>
              <td><input type="text" value={this.state.toBeInvoiced} placeholder={"Te factureren"} onChange={(event) => this.setState({ toBeInvoiced: event.target.value })}/></td>
            </tr>
            <tr>
              <td>Netto rekeningstand</td>
              <td><label>{netBalance ? "€ " + Math.round(netBalance).toLocaleString("de-DE") : "-"}</label></td>
            </tr>
          </tbody>
        </table>

        <p className={classes.subtitle} style={{ marginTop: "50px" }}>To be added</p>
        <ul style={{lineHeight: "27px"}}>
          <li>Statistieken over de verkoop: totaal, jaarlijks, maandelijks</li>
          <li>Grafieken van de sales</li>
          <li>Overzicht van betaalde en openstaande bedragen</li>
        </ul>
      </ContentContainer>
    )
  }
}

export default Financial;
