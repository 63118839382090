import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow";
import CheckboxRow from "../../../configuration/shared/CheckboxRow/CheckboxRow";
import classes from "./BookingModal.css"
import axios from "../../../../axios";

const directionOptions = [
  { label: "Inkomend", value: "in"},
  { label: "Uitgaand", value: "out"},
]
const transactionOptions = [
  { label: "Inkoop", value: "Inkoop"},
  { label: "Verkoop", value: "Verkoop"},
  { label: "Manufacturer sample", value: "Manufacturer sample"},
  { label: "Retournering", value: "Retournering"},
  { label: "Andere", value: "Andere"},
]


class ItemModal extends Component {

  state = {
    item: {},
    valuesChanged: false,
  }

  componentDidMount() {
  
    // Create an item if necessary
    let item = this.props.selectedItem
    if (item === null) {
      item = { 
        description: "",
        direction: "out",
        part: "",
        party: "",
        units: 0,
        transactionType: "Verkoop",
        timestamp: (new Date()) / 1000,
        backordered: false,
        notes: "",
      }
    }

    // Convert timestamp to date
    item.date = new Date(item.timestamp * 1000).toLocaleDateString("en-GB")

    this.setState({ item })
  }


  // MARK: - Handlers

  saveHandler = () => {
    // Post-processing
    let item = this.state.item
    // TODO: convert date to timestamp
    item.timestamp = dateStringToTimestamp(this.state.item.date)
    // Sync to the backend
    if (this.state.valuesChanged) {
      axios.post("/bookings/update", item)
    }
    // Close the modal view
    this.props.closeModal()
  }

  deleteHandler = () => {
    // Sync to the backend
    axios.delete("/bookings/delete/" + this.state.item._id)
    // Close the modal view
    this.props.closeModal()
  }

  updateProperty(property, value) {
    let item = {...this.state.item}
    item[property] = value
    this.setState({ item, valuesChanged: true })
  }


  // MARK: - User interface

  render() {

    const createMode = (this.props.selectedItem === null)

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h2>{createMode ? "Create new booking" : "Edit booking"}</h2>
        
        <InputRow
          title="Beschrijving"
          enabled
          ultrawide
          firstRow
          value={this.state.item.description ?? ""}
          placeholder={"E.g. Aankoop IOT2050"}
          onChange={(event) => this.updateProperty("description", event.target.value)}/>
        <InputRow
          title="Partij"
          enabled
          ultrawide
          firstRow
          value={this.state.item.party ?? ""}
          placeholder={"E.g. Siemens België"}
          onChange={(event) => this.updateProperty("party", event.target.value)}/>
        <SelectRow
          title="Richting"
          ultrawide
          increasedPadding
          value={this.state.item.direction}
          onChange={(newValue) => this.updateProperty("direction", newValue.value )}
          options={directionOptions}/>
        <SelectRow
          title="Onderdeel"
          ultrawide
          increasedPadding
          value={this.state.item.part ? this.state.item.part._id : ""}
          onChange={(newValue) => this.updateProperty("part", newValue.value )}
          options={this.props.partOptions}/>
        <InputRow
          title="Aantal eenheden"
          enabled
          ultrawide
          value={this.state.item.units ?? ""}
          placeholder={"E.g. 5"}
          onChange={(event) => this.updateProperty("units", event.target.value)}/>
        <InputRow
          title="Datum"
          enabled
          ultrawide
          value={this.state.item.date ?? ""}
          placeholder={"E.g. 01/01/2024"}
          onChange={(event) => this.updateProperty("date", event.target.value)}/>
        <SelectRow
          title="Transactietype"
          ultrawide
          increasedPadding
          value={this.state.item.transactionType}
          onChange={(newValue) => this.updateProperty("transactionType", newValue.value )}
          options={transactionOptions}/>
        {this.state.item.direction === "in" &&
        <CheckboxRow
          title="Opties"
          options={{ Backordered: this.state.item.backordered }}
          optionChanged={(option, checked) => {
            this.updateProperty("backordered", checked )
          }}/>}

        <textarea
            className={classes.notesField}
            value={this.state.item.notes}
            type="text"
            placeholder="Notities"
            onChange={(event) => this.updateProperty("notes", event.target.value)}>{this.state.item.notes}
        </textarea>
  
        <div className={classes.buttonRow}>
          {!createMode && <button className={classes.deleteButton} onClick={this.deleteHandler}>Delete</button>}
          <button className={(this.state.valuesChanged || createMode) ? classes.saveButton : classes.closeButton} onClick={this.saveHandler}>{createMode ? "Upload" : this.state.valuesChanged ? "Save" : "Close"}</button>
        </div>
      </Modal>
    );
  }
}

function dateStringToTimestamp(dateString) {
  var parts = dateString ? dateString.split("/") : []
  if (parts.length === 3) {
    var date = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
    return date.getTime() / 1000
  }
  return 0 
}

export default ItemModal;
