import React from "react";
import classes from "./ContentBox.css";

const contentBox = (props) => (
  <div className={classes.fullContainer}>
    <div className={classes.titleContainer}>
      <p>{props.title}</p>
    </div>
    <div className={classes.contentContainer}>
      {props.children}
    </div>
  </div>
);

export default contentBox;

/*
  This element must be enclosed within a div with the following styling:

    display: flex;
    flex-flow: column;
*/
