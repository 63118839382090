import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./customers.css"
import CustomerModal from "./CustomerModal/CustomerModal"

const columns = [
  { name: "Name", selector: "name", sortable: true, style: { fontSize: "15px" }},
  { name: "Representative", selector: "representative", sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "VAT number", selector: "vatNumber", sortable: true, right: true, style: { fontSize: "14px" }},
  { name: "Country", selector: "country", sortable: true, right: true, style: { fontSize: "14px" }},
]

class Customers extends Component {

  state = {
    customers: [],
    selectedCustomer: {},
    showCustomerModal: false
  }


  componentDidMount() {
    axios.get("/customers/all")
      .then(response => {
        if (response.data) {
          this.setState({ customers: response.data })
        }
      })
  }

  displayModal = (shouldBeShown, customer) => {
    this.setState({ showCustomerModal: shouldBeShown, selectedCustomer: customer })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/customers/all")
          .then(response => {
            if (response.data) {
              this.setState({ customers: response.data })
            }
          })
      }, 500)
    }
  }

  createCustomerHandler = () => {
    var customers = [...this.state.customers]
    customers.push({
      name: "New customer",
      representative: "",
      vatNumber: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
    })
    this.setState({ customers })
  }


  render() {
    return (
      <ContentContainer title="Klanten">
        <button onClick={this.createCustomerHandler} className={classes.addButton}>Add customer</button>
        <DataTable
          columns={columns}
          data={this.state.customers}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"name"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          onRowClicked={(customer) => this.displayModal(true, customer)}
        />


        {this.state.showCustomerModal && <>
        <CustomerModal
          shouldShow={this.state.showCustomerModal}
          selectedCustomer={this.state.selectedCustomer}
          closeModal={() => this.displayModal(false, {})}/>
        </>}

      </ContentContainer>
    );
  }
}

export default Customers;
