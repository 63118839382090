import React, { Component } from 'react';
import Layout from "./containers/Layout/Layout";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "./axios"

// Administration
import Customers  from "./components/1-administration/1-customers/customers";
import Orders     from "./components/1-administration/3-orders/orders";
import Invoices   from "./components/1-administration/4-invoices/invoices";
import Purchases  from "./components/1-administration/5-purchases/purchases";
import Financial  from "./components/1-administration/6-financial/financial";
import Cashout    from "./components/1-administration/7-cashout/cashout";

// Stock
import StockOverview    from "./components/3-stock/1-overview/overview";
import Bookings         from "./components/3-stock/2-bookings/bookings";
import Catalog          from "./components/3-stock/3-parts catalog/parts catalog";
import InUse          from "./components/3-stock/4-parts in use/parts in use";

// Deployments
import Fleet      from "./components/4-deployments/fleet/fleet";
import Licenses   from "./components/4-deployments/licenses/licenses";
import Statistics from "./components/4-deployments/statistics/statistics";

// Infrastructure
import Server   from "./components/5-infrastructure/server/server";
import Decoders from "./components/5-infrastructure/decoders/decoders";
import Images   from "./components/5-infrastructure/images/images";

import * as actions from "./store/actions/auth";
import * as privilegeActions from "./store/actions/privilege"

class App extends Component {


  componentDidMount() {
    // Try to restore session
    this.props.attemptSessionRestoration()

    // Check frontend privileges
    axios.get("/auth/privileges")
      .then(response => {
        this.props.updatePrivileges(response.data)
      }
    )
  }

  render() {

    // Redirect if necessary
    let redirect = null;
    // If not authenticated
    if (this.props.isAuthenticated === false && this.props.history.location.pathname !== "/") {
      redirect = (<Redirect to="/"/>);
    }
    // If authenticated
    if (this.props.isAuthenticated === true && this.props.history.location.pathname === "/") {
      redirect = (<Redirect to="/infrastructure/server"/>);
    }

    return (
      <div className="App">
        <Layout
            isAuthenticated={this.props.isAuthenticated}
            username={this.props.username}
            logout={this.props.logout}
            enableAdministation={this.props.hasAdministrationPrivilege}
            enableStock={this.props.hasStockPrivilege}
            enableDeployments={this.props.hasDeploymentPrivilege}>
          <Switch>
            <Route path="/administration/customers" component={Customers}></Route>
            <Route path="/administration/orders" component={Orders}></Route>
            <Route path="/administration/invoices" component={Invoices}></Route>
            <Route path="/administration/purchases" component={Purchases}></Route>
            <Route path="/administration/financial" component={Financial}></Route>
            <Route path="/administration/cashout" component={Cashout}></Route>

            <Route path="/stock/overview" component={StockOverview}></Route>
            <Route path="/stock/bookings" component={Bookings}></Route>
            <Route path="/stock/catalog" component={Catalog}></Route>
            <Route path="/stock/in-use" component={InUse}></Route>

            <Route path="/deployments/edit" component={Fleet}></Route>
            <Route path="/deployments/licenses" component={Licenses}></Route>
            <Route path="/deployments/statistics" component={Statistics}></Route>

            <Route path="/infrastructure/server" component={Server}></Route>
            <Route path="/infrastructure/decoders" component={Decoders}></Route>
            <Route path="/infrastructure/images" component={Images}></Route>
          </Switch>
        </Layout>
        {redirect}
      </div>
    );
  }
}


// MARK: - Redux

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    username: state.auth.username,
    hasAdministrationPrivilege: state.privilege.hasAdministrationPrivilege,
    hasStockPrivilege: state.privilege.hasStockPrivilege,
    hasDeploymentPrivilege: state.privilege.hasDeploymentPrivilege
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    attemptSessionRestoration: () => dispatch(actions.attemptSessionRestoration()),
    updatePrivileges: (list) => dispatch(privilegeActions.updatePrivileges(list)),
    logout: () => dispatch(actions.logout())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
