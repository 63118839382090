import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow";
import classes from "./PartModal.css"
import axios from "../../../../axios";

const categoryOptions = [
  { label: "IOT2050", value: "IOT2050"},
  { label: "R1000", value: "R1000"},
  { label: "LoRa modules", value: "LoRa modules"},
  { label: "LoRa antennae", value: "LoRa antennae"},
  { label: "U.FL connectors", value: "U.FL connectors"},
  { label: "4G modules", value: "4G modules"},
  { label: "4G antennae", value: "4G antennae"},
  { label: "Power supplies", value: "Power supplies"},
  { label: "TPM chips", value: "TPM chips"},
  { label: "Accessories", value: "Accessories"},
  { label: "LoRa devices", value: "LoRa devices"},
  { label: "Other", value: "Other"},
]


class ItemModal extends Component {

  state = {
    item: {},
    valuesChanged: false,
  }

  componentDidMount() {
  
    // Create an item if necessary
    let item = this.props.selectedItem
    if (item === null) {
      item = { 
        name: "",
        manufacturer: "",
        category: "Other",
        supplier: "",
        sku: "",
        purchasePrice: "",
        defaultSellingPrice: "",
        notes: "",
      }
    }

    // Change dot to comma
    if (item.purchasePrice) {
      item.purchasePrice = item.purchasePrice.toLocaleString("be-NL")
    }
    if (item.defaultSellingPrice) {
      item.defaultSellingPrice = item.defaultSellingPrice.toLocaleString("be-NL")
    }

    this.setState({ item })
  }


  // MARK: - Handlers

  saveHandler = () => {
    // Post-processing
    let item = this.state.item
    item.purchasePrice = item.purchasePrice ? item.purchasePrice.replace(/,/g, '.') : null
    item.defaultSellingPrice = item.defaultSellingPrice ? item.defaultSellingPrice.replace(/,/g, '.') : null
    // Sync to the backend
    if (this.state.valuesChanged) {
      axios.post("/parts/update", item)
    }
    // Close the modal view
    this.props.closeModal()
  }

  deleteHandler = () => {
    // Sync to the backend
    axios.delete("/parts/delete/" + this.state.item._id)
    // Close the modal view
    this.props.closeModal()
  }

  updateProperty(property, value) {
    let item = {...this.state.item}
    item[property] = value
    this.setState({ item, valuesChanged: true })
  }


  // MARK: - User interface

  render() {

    const createMode = (this.props.selectedItem === null)

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h2>{createMode ? "Add new part" : "Edit part"}</h2>
        
        <InputRow
          title="Naam"
          enabled
          ultrawide
          firstRow
          value={this.state.item.name ?? ""}
          placeholder={"E.g. IOT2050 Basic"}
          onChange={(event) => this.updateProperty("name", event.target.value)}/>
        <InputRow
          title="Manufacturer"
          enabled
          ultrawide
          value={this.state.item.manufacturer ?? ""}
          placeholder={"E.g. Siemens"}
          onChange={(event) => this.updateProperty("manufacturer", event.target.value)}/>
        <SelectRow
          title="Categorie"
          ultrawide
          increasedPadding
          value={this.state.item.category}
          onChange={(newValue) => this.updateProperty("category", newValue.value )}
          options={categoryOptions}/>
        <InputRow
          title="Leverancier"
          enabled
          ultrawide
          value={this.state.item.supplier ?? ""}
          placeholder={"E.g. Digikey"}
          onChange={(event) => this.updateProperty("supplier", event.target.value)}/>
        <InputRow
          title="SKU"
          enabled
          ultrawide
          value={this.state.item.sku ?? ""}
          placeholder={"E.g. 113991274"}
          onChange={(event) => this.updateProperty("sku", event.target.value)}/>
        <InputRow
          title="Aankoopprijs"
          enabled
          ultrawide
          value={this.state.item.purchasePrice ?? ""}
          placeholder={"Aankoopprijs"}
          onChange={(event) => this.updateProperty("purchasePrice", event.target.value)}/>
        <InputRow
          title="Verkoopprijs"
          enabled
          ultrawide
          value={this.state.item.defaultSellingPrice ?? ""}
          placeholder={"Standaard verkooopprijs"}
          onChange={(event) => this.updateProperty("defaultSellingPrice", event.target.value)}/>

        <textarea
            className={classes.notesField}
            value={this.state.item.notes}
            type="text"
            placeholder="Notities"
            onChange={(event) => this.updateProperty("notes", event.target.value)}>{this.state.item.notes}
        </textarea>
  
        <div className={classes.buttonRow}>
          {!createMode && <button className={classes.deleteButton} onClick={this.deleteHandler}>Delete</button>}
          <button className={(this.state.valuesChanged || createMode) ? classes.saveButton : classes.closeButton} onClick={this.saveHandler}>{createMode ? "Upload" : this.state.valuesChanged ? "Save" : "Close"}</button>
        </div>
      </Modal>
    );
  }
}

export default ItemModal;
