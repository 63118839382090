import React from "react";
import classes from "./InputRow.css"


const infoRow = (props) => {

  const outerClasses = [classes.rowContainer];
  const fieldClasses = [classes.inputField];

  // Define outer CSS
  if (props.firstRow === true) {
    outerClasses.push(classes.isFirstRow);
  }
  if (props.extendedWidth === true) {
    fieldClasses.push(classes.extendedWidth);
  }
  if (props.ultrawide === true) {
    fieldClasses.push(classes.ultrawide);
  }

  // Define field CSS
  if (props.validateAsIP === true) {
    if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(props.value))) {
      fieldClasses.push(classes.invalid)
    }
  }
  if (props.invalid === true) {
    fieldClasses.push(classes.invalid)
  }

  return (
    <div className={outerClasses.join(" ")}>
      <p className={classes.title}>{props.title}</p>
      <input
        className={fieldClasses.join(" ")}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        disabled={!props.enabled}
        autoComplete="off"
        type={props.containsPassword ? "password" : "input"}/>
    </div>
  );
};


export default infoRow;
