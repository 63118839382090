import React from "react";
import classes from "./ContentContainer.css";


const contentContainer = (props) => (
  <>
    <p className={classes.title}>{props.title}</p>
    <hr className={classes.hr}/>
    <div className={classes.container}>
      {props.children}
    </div>
  </>
);

export default contentContainer;
