import React from "react";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";

const Orders = (props) => {

  return (
    <ContentContainer title="Bestellingen">
      <h3>Status</h3>Nog te implementeren
      <h3>Opzet</h3>
      Aanmaken van een bestelling voor een bepaalde klant.
      <ul style={{lineHeight: "27px"}}>
        <li>Offerte genereren op basis hiervan</li>
        <li>Bijhouden van de status: besteld, verstuurd, betaald, enz.</li>
        <li>Factuur genereren en automatisch doormailen</li>
        <li>Licentie aanmaken op basis van bestelling</li>
      </ul>

    </ContentContainer>
  );
}

export default Orders;
