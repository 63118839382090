import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./fleet.css"
import GatewayModal from "./GatewayModal/GatewayModal"



class Fleet extends Component {

  columns = [
    { name: "Friendly name", selector: "trivialName", sortable: true, left: true, minWidth: "180px", style: { fontSize: "14px" }},
    { name: "Serial number", selector: "serialNumber", sortable: true, left: true, style: { fontSize: "14px" }},
    { name: "Customer", selector: "customer.name", sortable: true, minWidth: "230px", style: { fontSize: "15px" }},
    { name: "Firmware", selector: "versionNumber", sortable: true, left: true, maxWidth: "20px", style: { fontSize: "14px" }},
    { name: "Location", selector: "location", sortable: true, right: true, style: { fontSize: "14px" }},
  ]

  state = {
    gateways: [],
    showGatewayModal: false,
    selectedGateway: {},
    customersDict: {},
    customersOptions: []
  }

  componentDidMount() {
    axios.get("/fleet/all")
      .then(response => {
        if (response.data) {
          this.setState({ gateways: response.data })
        }
      })

    axios.get("/customers/allReduced")
      .then(response => {
        if (response.data) {

          // Map into Select component
          const select = response.data.map((el) => {
            return { label: el.name, value: el._id }
          })

          this.setState({ customersOptions: select})
        }
      })
  }

  displayModal = (shouldBeShown, gateway) => {
    this.setState({ showGatewayModal: shouldBeShown, selectedGateway: gateway })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/fleet/all")
          .then(response => {
            if (response.data) {
              this.setState({ gateways: response.data })
            }
          })
      }, 500)
    }
  }

  createGatewayHandler = () => {
    var gateways = [...this.state.gateways]
    let newGateway = {
      trivialName: "New gateway",
      location: "",
      cpuUUID: "",
      serialNumber: "",
      model: "",
      pcieExpansion: "",
      customer: {
        _id: "",
        name: "",
      }
    }
    gateways.push(newGateway)
    // Add and immediately show
    this.setState({ gateways: gateways, showGatewayModal: true, selectedGateway: newGateway })
  }


  render() {
    return (
      <ContentContainer title="Gateway fleet">
        <button onClick={this.createGatewayHandler} className={classes.addButton}>Add gateway</button>
        <DataTable
          columns={this.columns}
          data={this.state.gateways}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"serialNumber"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          className={classes.messageTable}
          onRowClicked={(gateway) => this.displayModal(true, gateway)}
        />


        {this.state.showGatewayModal && <>
        <GatewayModal
          shouldShow={this.state.showGatewayModal}
          selectedGateway={this.state.selectedGateway}
          customers={this.state.customersOptions}
          closeModal={() => this.displayModal(false, {})}/>
        </>}

      </ContentContainer>
    );
  }

  timeDifferenceString = (epoch) => {

    let lastOnline = epoch;

    const secondsPerMinute = 60;
    const secondsPerHour = secondsPerMinute * 60;
    const secondsPerDay = secondsPerHour * 24;
    const secondsPerMonth = secondsPerDay * 30;
    const secondsPerYear = secondsPerDay * 365;
    const elapsedSeconds = (new Date() / 1000) - lastOnline;

    let relativeDifference = null;
    let timeUnit = null;

    // eslint-disable-next-line
    if (lastOnline == 0) {
      return "Never"
    }
    if (elapsedSeconds < secondsPerMinute) {
      relativeDifference = Math.round(elapsedSeconds);
      timeUnit = "second"
    }
    else if (elapsedSeconds < secondsPerHour) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerMinute);
      timeUnit = "minute"
    }
    else if (elapsedSeconds < secondsPerDay ) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerHour);
      timeUnit = "hour"
    }
    else if (elapsedSeconds < secondsPerMonth) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerDay);
      timeUnit = "day"
    }
    else if (elapsedSeconds < secondsPerYear) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerMonth);
      timeUnit = "month"
    }
    else {
      relativeDifference = Math.round(elapsedSeconds/secondsPerYear);
      timeUnit = "year"
    }


    if (relativeDifference === 1) {
      return (relativeDifference + " " + timeUnit + " ago");
    } else {
      return (relativeDifference + " " + timeUnit + "s ago");
    }
  }
}

export default Fleet;
