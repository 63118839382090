import React from "react";
import ContentBox from "../../../../containers/UI/ContentBox/ContentBox";
import InfoRow from "../InfoRow/InfoRow";

const memory = (props) => {

  let totalMem = ""
  let usedMem = ""
  let freeMem = ""
  let freeDisk = ""

  if (props.memory) {
    totalMem = parseFloat((props.memory.total * 0.00000095367432).toFixed(0)).toLocaleString("en");
    usedMem = parseFloat((props.memory.used * 0.00000095367432).toFixed(0)).toLocaleString("en");
    freeMem = parseFloat((props.memory.free * 0.00000095367432).toFixed(0)).toLocaleString("en");
    freeDisk = (props.fs[0].use).toFixed(1);
  }

  return (
    <ContentBox title="Memory">
      <div style={{display: "flex", "flexFlow": "column"}}>
        <InfoRow
          title="Total memory"
          body={totalMem + " MB"} firstRow={true}/>
        <InfoRow
          title="Used memory"
          body={usedMem + " MB"}/>
        <InfoRow
          title="Free memory"
          body={freeMem + " MB"}/>
        <InfoRow
          title="Free disk space"
          body={freeDisk + "%"}/>
      </div>
    </ContentBox>
  );
}

export default memory;
