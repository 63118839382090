import React from "react";
import classes from "./CheckboxRow.css"


const checkboxRow = (props) => {

  const outerClasses = [classes.rowContainer];
  if (props.firstRow === true) {
    outerClasses.push(classes.isFirstRow);
  }

  // Construct checkbox table
  let boxTable = Object.keys(props.options).map((option) => {
    return <>
      <td>
        <input
          type="checkbox"
          checked={props.options[option]}
          className={classes.checkbox}
          onChange={(event) => {
            props.optionChanged(option, event.target.checked)
          }}/>  
      </td>
      <td>{option}</td>
    </>
  })


  return (
    <div className={classes.rowContainer}>
      <p className={classes.rowTitle}>{props.title}</p>
      <table className={classes.boxTable}>
        <tbody>
          <tr className={classes.boxRow}>
            {boxTable}
          </tr>
        </tbody>
      </table>
    </div>  
  );
};


export default checkboxRow;
