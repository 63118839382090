import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./parts in use.css"
import Modal from "./PartModal/PartModal"

const columns = [
  { name: "Naam", selector: "name", sortable: true, left: true, style: { fontSize: "15px" }, maxWidth: "350px"},
  { name: "Aantal in gebruik", selector: "unitsInUse", sortable: true, left: true, style: { fontSize: "15px" }, maxWidth: "300px", format: (row) => row.unitsInUse + " stuk" + (row.unitsInUse !== 1 ? "s" : "")},
  { name: "Beschrijving", selector: "usageDescription", sortable: true, left: true, style: { fontSize: "15px" }},
]

class PartsCatalog extends Component {

  state = {
    items: [],
    selectedItem: null,
    showModal: false,
    partOptions: []
  }


  componentDidMount() {

    // Fetch all parts in use
    axios.get("/parts/in-use").then(response => {
      if (response.data) {
        this.setState({ items: response.data })
      }
    })

    // Fetch all parts in reduced form
    axios.get("/parts/all/reduced").then(response => {
      if (response.data) {
        // Create options
        let options = []
        for (let part of response.data) {
          if (!(part.unitsInUse > 0)) {
            options.push({ label: part.name, value: part._id })
          }
        }
        this.setState({ partOptions: options })
      }
    })
  }

  displayModal = (shouldBeShown, item) => {
    this.setState({ showModal: shouldBeShown, selectedItem: item })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/parts/in-use").then(response => {
          if (response.data) {
            this.setState({ items: response.data })
          }
        })
      }, 500)
    }
  }

  render() {
    return (
      <ContentContainer title="Parts in use">
        <button onClick={() => this.displayModal(true, null)} className={classes.addButton}>Add part</button>

        <DataTable
          striped
          noHeader
          pagination
          highlightOnHover
          columns={columns}
          data={this.state.items}
          defaultSortField={"name"}
          defaultSortAsc={false}
          customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}
          onRowClicked={(item) => this.displayModal(true, item)}
        />

        {this.state.showModal && <>
          <Modal
            shouldShow={this.state.showModal}
            selectedItem={this.state.selectedItem}
            partOptions={this.state.partOptions}
            closeModal={() => this.displayModal(false, null)}/>
          </>
        }

      </ContentContainer>
    );
  }
}

export default PartsCatalog;
