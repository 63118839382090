import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow";
import classes from "./CashoutModal.css"
import axios from "../../../../axios";


class InvoiceModal extends Component {

  state = {
    valuesChanged: false,
  }

  componentDidMount() {

    let cashout = this.props.selectedCashout

    // Create an cashout if necessary
    if (cashout === null) {
      cashout = { 
        description: "", 
        recipient: "", 
        amount: "",
        date: new Date()
      }
    } else {
      cashout.date = new Date(cashout.date * 1000)
    }

    // Parse date to text
    let jsDate = cashout.date
    cashout.date = String(jsDate.getDate()).padStart(2, '0') + "/" + String(jsDate.getMonth() + 1).padStart(2, '0') + "/" + jsDate.getFullYear()

    this.setState({ ...cashout })
  }

  updateProperty(property, value) {
    let update = { valuesChanged: true }
    update[property] = value
    this.setState(update)
  }

  saveCashout = () => {

    // Parse additional properties into invoice
    let cashout = this.state
  
    // Convert European date to JavaScript date
    var parts = cashout.date ? cashout.date.split("/") : []
    if (parts.length === 3) {
      var date = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
      cashout.date = date.getTime() / 1000
    }

    // Sync to the backend
    if (this.state.valuesChanged) {
      axios.post("/cashout/update", cashout)
    }

    // Close the modal view
    this.props.closeModal()
  }

  deleteHandler = () => {
    // Sync to the backend
    axios.delete("/cashout/delete/" + this.state._id)
    // Close the modal view
    this.props.closeModal()
  }

  render() {

    let createMode = (this.props.selectedCashout === null)

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h2>{createMode ? "Uitbetaling toevoegen" : "Uitbetaling aanpassen"}</h2>
        
        <SelectRow
          title="Ontvanger"
          firstRow
          ultrawide
          value={this.state.recipient}
          onChange={(newValue) => this.updateProperty("recipient", newValue.value)}
          options={[{ value: "bram", label: "Bram" }, { value: "filip", label: "Filip" }]}/>
        <InputRow
          title="Beschrijving"
          enabled
          ultrawide
          firstRow
          value={this.state.description}
          placeholder={"E.g. BMW Z4"}
          onChange={(event) => this.updateProperty("description", event.target.value)}/>
        <InputRow
          title="Bedrag zonder BTW"
          enabled
          ultrawide
          value={this.state.amount}
          placeholder={"Amount"}
          onChange={(event) => this.updateProperty("amount", event.target.value)}/>
        <InputRow
          title="Datum"
          enabled
          ultrawide
          value={this.state.date}
          placeholder={"E.g. 15/01/2024"}
          onChange={(event) => this.updateProperty("date", event.target.value)}/>

        <div className={classes.buttonRow}>
          {!createMode && <button className={classes.deleteButton} onClick={this.deleteHandler}>Delete</button>}
          <button className={(this.state.valuesChanged || createMode) ? classes.saveButton : classes.closeButton} onClick={this.saveCashout}>{createMode ? "Upload" : this.state.valuesChanged ? "Save" : "Close"}</button>
        </div>
      </Modal>
    );
  }
}

export default InvoiceModal;
