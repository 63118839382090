import * as actionTypes from "../actions/actionTypes";

// MARK: - State structure
const initialState = {
  token: null,
  isAuthenticated: null,
  authError: null
}


// MARK: - Reducer
const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.AUTH_START: return {token: null, username: null, isAuthenticated: false, authError: null}
    case actionTypes.AUTH_SUCCESS: return {token: action.token, username: action.username, isAuthenticated: true, authError: null}
    case actionTypes.AUTH_FAIL: return {token: null, username: null, isAuthenticated: false, authError: action.error}
    case actionTypes.AUTH_LOGOUT: return {token: null, username: null, isAuthenticated: false, authError: null}
    default: return state;
  }
}

export default authReducer
