import React, { Component } from "react";
import axios from "../../../axios";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import classes from "./server.css"
import SystemInfo from "./SystemInfo/SystemInfo";
import Processor from "./Processor/Processor";
import Memory from "./Memory/Memory";

class SysInfo extends Component {

  state = {
    fs: null,
    load: null,
    memory: null,
    os: null,
    time: null,
    interface: {},
  }

  componentDidMount() {

    axios.get("/systemStatus")
      .then(response => {
        this.setState({
          fs: response.data.fs,
          load: response.data.load,
          memory: response.data.memory,
          os: response.data.os,
          time: response.data.time,
          interface: response.data.interface ?? {}
        });
      });
  }

  render() {

    return (
      <ContentContainer title="Server status">
        <div className={classes.contentContainer}>
          <div className={classes.topContainer}>
            <SystemInfo os={this.state.os} time={this.state.time} interface={this.state.interface}/>
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.bottomLeft}>
              <Processor load={this.state.load}/>
            </div>
            <div className={classes.bottomRight}>
              <Memory memory={this.state.memory} fs={this.state.fs}/>
            </div>
          </div>
        </div>
      </ContentContainer>
    );
  }
}

export default SysInfo;
