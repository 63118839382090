import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import CheckboxRow from "../../../configuration/shared/CheckboxRow/CheckboxRow";
import classes from "./GenerateLicense.css"
import axios from "../../../../axios";


class GenerateLicense extends Component {

  state = {
    gatewayOptions: {},
    chosenGateway: "",
    validity: "30years",
    isForwarderSlave: false,
    forwarderCount: "",
    enableInternalRadio: true,
    useNewFormat: true,
    loraDeviceLimit: "250",
    protocols: { BACnet: true, KNX: false, Modbus: false, MQTT: false }
  }

  validityOptions = [
    { value: "1months",   label: "1 month (demo)"},
    { value: "3months",   label: "3 months (demo)"},
    { value: "1years",    label: "1 year"},
    { value: "3years",    label: "3 years"},
    { value: "5years",    label: "5 years"},
    { value: "10years",   label: "10 years"},
    { value: "20years",   label: "20 years"},
    { value: "30years",   label: "30 years"}]
  licenseOptions = [
    { value: "master", label: "Master gateway"},
    { value: "slave", label: "Forwarder gateway"}]
  radioOptions = [
    { value: "enable", label: "Enable internal radio"},
    { value: "disable", label: "Disable internal radio"}]
  formatOptions = [
    { value: "new", label: "Version 1.3 or later"},
    { value: "old", label: "Version 1.2 or ealier"}]
  deviceOptions = [
    { value: "250", label: "250 devices (included)"},
    { value: "350", label: "350 devices"},
    { value: "450", label: "450 devices"},
    { value: "550", label: "550 devices"},
    { value: "650", label: "650 devices"},
    { value: "750", label: "750 devices"},
    { value: "850", label: "850 devices"},
    { value: "950", label: "950 devices"},
    { value: "1050", label: "1.050 devices"},
    { value: "1150", label: "1.150 devices"},
    { value: "2500", label: "2.500 devices"},
    { value: "5000", label: "5.000 devices"},
    { value: "10000", label: "10.000 devices"}]

  componentDidMount() {
    axios.get("/fleet/all")
      .then(response => {
        if (response.data) {
          this.setState({ gatewayOptions: response.data.map(item => { return { label: item.serialNumber + " - " + item.trivialName, value: item._id }}), gateways: response.data })
        }
      })
  }


  generateLicense = () => {

    if (this.state.chosenGateway !== "") {

      // Check if it has a valid CPU uuid
      let gateway = this.state.gateways.find(el => el._id === this.state.chosenGateway)

      if (gateway.cpuUUID) {
        if (gateway.cpuUUID.length === 32 || gateway.cpuUUID.length === 16) { // Siemens is 32 characters, Seeed is 16 characters
          if (this.state.forwarderCount || (this.state.isForwarderSlave === true)) {
            if (!isNaN(this.state.forwarderCount) || (this.state.isForwarderSlave === true)) {


              // Check for an existing license
              if (gateway.license) {
                if (!window.confirm("A license is already linked to this gateway. Do you want to overwrite this license?")) {
                  return;
                }
              }

              // Create to be generated license
              var license = {
                gatewayId: this.state.chosenGateway,
                validity: this.state.validity,
                useNewFormat: this.state.useNewFormat,
                forwarderCount: this.state.forwarderCount,
                isForwarderSlave: this.state.isForwarderSlave,
                enableInternalRadio: this.state.enableInternalRadio,
                loraDeviceLimit: this.state.loraDeviceLimit,
                protocols: Object.keys(this.state.protocols).filter(v => this.state.protocols[v] === true)
              }

              // Sync to the backend
              axios.post("/license/generate", license);
              // Close the modal view
              this.props.closeModal()

            } else { alert("Please enter a valid number of allowed forwarders.") }
          } else { alert("Please enter a valid number of allowed forwarders.") }
        } else { alert("This gateway does not have a valid CPU UUID. \nPlease fill in a valid CPU UUID first.") }
      } else { alert("This gateway does not have a valid CPU UUID. \nPlease fill in a valid CPU UUID first.") }



    } else {
      alert("Please select a serial number.")
    }
  }


  render() {

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h3>Generate new license</h3>
        <SelectRow
          title="Gateway"
          firstRow
          ultrawide
          increasedPadding
          value={this.state.chosenGateway}
          onChange={(newValue) => this.setState({ chosenGateway: newValue.value})}
          options={this.state.gatewayOptions}/>
        <SelectRow
          title="Validity period"
          ultrawide
          increasedPadding
          value={this.state.validity ?? ""}
          onChange={(newValue) => this.setState({ validity: newValue.value })}
          options={this.validityOptions}/>
        <SelectRow
          title="License format"
          ultrawide
          increasedPadding
          value={(this.state.useNewFormat === true) ? "new" : "old"}
          onChange={(newValue) => this.setState({ useNewFormat: (newValue.value === "new") })}
          options={this.formatOptions}/>
        {this.state.useNewFormat === true &&
        <SelectRow
          title="License type"
          ultrawide
          increasedPadding
          value={(this.state.isForwarderSlave === true) ? "slave" : "master"}
          onChange={(newValue) => this.setState({ isForwarderSlave: (newValue.value === "slave") })}
          options={this.licenseOptions}/>}
        {this.state.isForwarderSlave !== true && this.state.useNewFormat === true &&
        <SelectRow
          title="Internal radio"
          ultrawide
          increasedPadding
          value={(this.state.enableInternalRadio === true) ? "enable" : "disable"}
          onChange={(newValue) => this.setState({ enableInternalRadio: (newValue.value === "enable") })}
          options={this.radioOptions}/>}
        {this.state.isForwarderSlave !== true &&
        <InputRow
          enabled
          ultrawide
          title="Number of forwarders"
          placeholder="Enter a number"
          value={this.state.forwarderCount}
          onChange={(event) => this.setState({ forwarderCount: event.target.value })}/>}
        {this.state.isForwarderSlave !== true && this.state.useNewFormat === true && <>
          <SelectRow
            title="LoRa device limit"
            ultrawide
            increasedPadding
            value={this.state.loraDeviceLimit}
            onChange={(newValue) => this.setState({ loraDeviceLimit: newValue.value })}
            options={this.deviceOptions}/>

          <CheckboxRow
            title="Protocol conversion"
            options={this.state.protocols}
            optionChanged={(option, checked) => {
              let protocols = {...this.state.protocols}
              protocols[option] = checked
              this.setState({ protocols })
            }}/>  
        </>}
        

        <div className={classes.buttonRow}>
          <button className={classes.cancelButton} onClick={this.props.closeModal}>Cancel</button>
          <button className={classes.generateButton} onClick={this.generateLicense}>Generate</button>
        </div>
      </Modal>
    );
  }
}

export default GenerateLicense;
