import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import StatisticsModal from "./StatisticsModal/StatisticsModal"



class Fleet extends Component {

  state = {
    gateways: [],
    showGatewayModal: false,
    selectedGateway: {},
  }

  columns = [
    { name: "Friendly name", selector: "trivialName", sortable: true, left: true, style: { fontSize: "14px" }},
    { name: "Serial number", selector: "serialNumber", sortable: true, left: true, style: { fontSize: "14px" }},
    { name: "Last online", selector: "lastOnline", sortable: true, maxWidth: "170px", minWidth: "140px", style: { fontSize: "13px" },
      format: (row, index) => { return this.timeDifferenceString(row.lastOnline) }},
    { name: "Firmware", selector: "versionNumber", sortable: true, left: true, maxWidth: "20px", style: { fontSize: "14px" }},
    { name: "Customer", selector: "customer.name", sortable: true, left: true, minWidth: "200px", style: { fontSize: "15px" }},
    { name: "Location", selector: "location", sortable: true, right: true, maxWidth: "130px", style: { fontSize: "14px" }},
  ]

  componentDidMount() {
    axios.get("/fleet/all")
      .then(response => {
        if (response.data) {
          this.setState({ gateways: response.data })
        }
      })
  }

  displayModal = (shouldBeShown, gateway) => {
    this.setState({ showGatewayModal: shouldBeShown, selectedGateway: gateway })
  }


  render() {
    return (
      <ContentContainer title="Gateway usage statistics">
        <DataTable
          columns={this.columns}
          data={this.state.gateways}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"lastOnline"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          onRowClicked={(gateway) => this.displayModal(true, gateway)}
        />


        {this.state.showGatewayModal && <>
        <StatisticsModal
          shouldShow={this.state.showGatewayModal}
          gateway={this.state.selectedGateway}
          timeDifferenceString={this.timeDifferenceString}
          closeModal={() => this.displayModal(false, {})}/>
        </>}

      </ContentContainer>
    );
  }

  timeDifferenceString = (epoch) => {

    let lastOnline = epoch;

    const secondsPerMinute = 60;
    const secondsPerHour = secondsPerMinute * 60;
    const secondsPerDay = secondsPerHour * 24;
    const secondsPerMonth = secondsPerDay * 30;
    const secondsPerYear = secondsPerDay * 365;
    const elapsedSeconds = (new Date() / 1000) - lastOnline;

    let relativeDifference = null;
    let timeUnit = null;

    // eslint-disable-next-line
    if (lastOnline == 0) {
      return "Never"
    }
    if (elapsedSeconds < secondsPerMinute) {
      relativeDifference = Math.round(elapsedSeconds);
      timeUnit = "second"
    }
    else if (elapsedSeconds < secondsPerHour) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerMinute);
      timeUnit = "minute"
    }
    else if (elapsedSeconds < secondsPerDay ) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerHour);
      timeUnit = "hour"
    }
    else if (elapsedSeconds < secondsPerMonth) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerDay);
      timeUnit = "day"
    }
    else if (elapsedSeconds < secondsPerYear) {
      relativeDifference = Math.round(elapsedSeconds/secondsPerMonth);
      timeUnit = "month"
    }
    else {
      relativeDifference = Math.round(elapsedSeconds/secondsPerYear);
      timeUnit = "year"
    }


    if (relativeDifference === 1) {
      return (relativeDifference + " " + timeUnit + " ago");
    } else {
      return (relativeDifference + " " + timeUnit + "s ago");
    }
  }
}

export default Fleet;
