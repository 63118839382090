import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./parts catalog.css"
import Modal from "./PartModal/PartModal"

const columns = [
  { name: "Naam", selector: "name", sortable: true, left: true, style: { fontSize: "15px" }},
  { name: "Leverancier", selector: "supplier", sortable: true, style: { fontSize: "14px" } },
  { name: "Categorie", selector: "category", sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Aankoopprijs", selector: "purchasePrice", sortable: true, style: { fontSize: "15px" }, format: (row) => { return formatCurrency(row.purchasePrice)}},
  { name: "SKU", selector: "sku", sortable: true, left: true, style: { fontSize: "14px" }},
]

class PartsCatalog extends Component {

  state = {
    items: [],
    selectedItem: null,
    showModal: false,
  }


  componentDidMount() {

    // Fetch all invoices
    axios.get("/parts/all").then(response => {
      if (response.data) {
        this.setState({ items: response.data })
      }
    })
  }

  displayModal = (shouldBeShown, invoice) => {
    this.setState({ showModal: shouldBeShown, selectedItem: invoice })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/parts/all").then(response => {
          if (response.data) {
            this.setState({ items: response.data })
          }
        })
      }, 500)
    }
  }

  render() {
    return (
      <ContentContainer title="Parts catalog">
        <button onClick={() => this.displayModal(true, null)} className={classes.addButton}>Add part</button>

        <DataTable
          striped
          noHeader
          pagination
          highlightOnHover
          columns={columns}
          data={this.state.items}
          defaultSortField={"name"}
          defaultSortAsc={false}
          customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}
          onRowClicked={(item) => this.displayModal(true, item)}
        />

        {this.state.showModal && <>
          <Modal
            shouldShow={this.state.showModal}
            selectedItem={this.state.selectedItem}
            closeModal={() => this.displayModal(false, null)}/>
          </>
        }

      </ContentContainer>
    );
  }
}

function formatCurrency(amount) {
  if (amount !== undefined) {
    return "€ " + parseFloat(amount).toLocaleString("de-DE")
  }
  return ""
}

export default PartsCatalog;
