import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./decoders.css"
import CustomerModal from "./SoftwareModal/SoftwareModal"

const columns = [
  { name: "Version number", selector: "versionSortValue", sortable: true, left: true, style: { fontSize: "15px" }, format: row => row.versionNumber },
  { name: "Release date", selector: "releaseDate", sortable: false, style: { fontSize: "14px" }},
  { name: "Changelog", selector: "changelogMessage", sortable: false, grow: 3, style: { fontSize: "13px" }},

]

class Customers extends Component {

  state = {
    decoderVersions: [],
    showSoftwareModal: false,
    createNewMode: false,
    selectedSoftware: {}
  }


  componentDidMount() {
    axios.get("/decoders/all")
      .then(response => {
        if (response.data) {

            // Prepare builds for sorting
          let decoders = response.data
          let largestPossibleVersion = 100000
          decoders = decoders.map(a => {
            a.versionSortValue = a.versionNumber.split('.').map( n => + n + largestPossibleVersion).join('.')
            return a
          })
          console.log(decoders)
          
          this.setState({ decoderVersions: response.data })
        }
      })
  }

  displayModal = (shouldBeShown, software) => {
    this.setState({ showSoftwareModal: shouldBeShown, selectedSoftware: software, createNewMode: false })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/decoders/all")
          .then(response => {
            if (response.data) {
              this.setState({ decoderVersions: response.data })
            }
          })
      }, 500)
    }
  }

  createDecoderHandler = () => {
    var decoders = [...this.state.decoderVersions]
    var newDecoder = {
      softwareComponent: "LoRa decoder library",
      versionNumber: "",
      releaseDate: "",
      changelogMessage: ""
    }
    decoders.push(newDecoder)
    this.setState({ decoderVersions: decoders, selectedSoftware: newDecoder, showSoftwareModal: true, createNewMode: true })
  }


  render() {
    return (
      <ContentContainer title="Software updates">
        <div className={classes.header}>
          <h2 className={classes.headerTitle}>Decoder library</h2>
          <button onClick={this.createDecoderHandler} className={classes.addButton}>Add version</button>
        </div>
        <DataTable
          columns={columns}
          data={this.state.decoderVersions}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"versionSortValue"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          onRowClicked={(software) => this.displayModal(true, software)}
        />


        {this.state.showSoftwareModal && <>
        <CustomerModal
          addMode={this.state.createNewMode}
          shouldShow={this.state.showSoftwareModal}
          selectedSoftware={this.state.selectedSoftware}
          closeModal={() => this.displayModal(false, {})}/>
        </>}

      </ContentContainer>
    );
  }
}

export default Customers;
