import React from "react";
import classes from "./Modal.css";
import Backdrop from "./Backdrop/Backdrop";

const modal = (props) => {

  let modalClass = ""
  if (props.ultrawide === true) {
    modalClass = [classes.Modal, classes.ultrawide].join(" ")
  } if (props.bigmodal === true) {
    modalClass = [classes.Modal, classes.bigmodal].join(" ")
  } else {
    modalClass = classes.Modal
  }

  return (
    <>
      <Backdrop show={props.shouldShow} wasClicked={props.closeModal}/>
      <div
        className={modalClass}
        style={{
          display: props.shouldShow ? "inline" : "none"
        }}>
        {props.children}
      </div>
    </>
  );
}

export default modal;
