import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./overview.css"

import FilterModal from "./FilterModal/FilterModal";
import { ReactComponent as FilterIcon } from "./FilterModal/Filter.svg";
import { calculateStock } from "./stockCalculator.js"

import R1000 from "../../images/R1000.png"
import IOT2050 from "../../images/IOT2050.png"

/*
  Naam, categorie, SKU, aantal stuks in stock
*/
const columns = [
  { name: "Onderdeel", selector: "name", sortable: true, left: true, style: { fontSize: "15px" }},
  { name: "Categorie", selector: "category", sortable: true, left: true, style: { fontSize: "14px" }, sortFunction: categorySort },
  { name: "SKU", selector: "sku", sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "In stock", selector: "stock", sortable: true, center: true, style: { fontSize: "15px" }, cell: row => {
    if (row.hasOwnProperty("stock")) {
      return <div className={classes.stockIndicator} style={{ backgroundColor: colorForStock(row.stock) }}>
        {(row.stock === 0) ? "Out of stock" : (row.stock + " stuk" + (row.stock !== 1 ? "s" : ""))}
      </div>
    } else {
      return ""
    }
  }},
  { name: "Opmerking", selector: "note", sortable: false, center: true, style: { fontSize: "14px" }, format: (row) => {
    let messages = []

    if (row.backorder !== 0) {
      messages.push(row.backorder + " in backorder")
    }

    if (row.unitsInUse > 0) {
      messages.push(row.unitsInUse + " in use")
    }

    return messages.join(", ")
  }},
  { name: "Waarde", selector: "value", sortable: true, center: true, style: { fontSize: "15px" }, format: (row) => { return formatCurrency(row.value)}},
]

const categoryOrder = ["IOT2050", "R1000", "LoRa modules", "U.FL connectors", "LoRa antennae", "Power supplies", "TPM chips", "4G modules", "4G antennae", "Other", "LoRa devices"];


class StockOverview extends Component {

  state = {
    items: [{ stock: 1 }, { stock: 0 }, { stock: 3 }],
    filteredItems: [],
    showModal: false,
    showFilter: false,
    filteredCategory: "all-categories",
    categoryOptions: [],
    categoryStock: {},
    stats: {}
  }


  componentDidMount() {

    // Fetch all parts
    axios.get("/parts/all").then(response => {
      if (response.data) {

        let parts = response.data

        axios.get("/bookings/all").then(response => {
          if (response.data) {

            let bookings = response.data
            let stock = calculateStock(parts, bookings)

            this.setState({ categoryOptions: stock.categories, stats: stock.stats, categoryStock: stock.categoryStock })
            this.updateItems(stock.partRows)
          }
        })
      }
    })
  }

 
  // MARK: - Filtering helper

  updateItems = (items, newFilter = null) => {

    // If no items given, use state
    let unfilteredItems = items
    if (items === null) {
      unfilteredItems = this.state.items
    }

    // If no filter given, use state
    let filter = newFilter
    if (filter === null) {
      filter = this.state.filteredCategory
    }

    // Filter appropriately
    let filteredItems = unfilteredItems

    if (filter !== "all-categories") {
      filteredItems = unfilteredItems.filter(item => item.category === filter)
    }

    // Set items
    this.setState({ items: unfilteredItems, filteredItems: filteredItems, filteredCategory: filter })
  }

  render() {
    return (
      <ContentContainer title="Overzicht">

      <div className={classes.filterRow}>
          <button className={(this.state.filteredCategory !== "all-categories") ? classes.filtered : classes.unfiltered } onClick={() => this.setState({ showFilter : true })}>
            {(this.state.filteredCategory !== "all-categories") ? "Filtered" : "Filter"}
            <FilterIcon fill={(this.state.filteredCategory !== "all-categories") ? "#FF6315" : "black"} className={classes.filterIcon}/>
          </button>
        </div>

        <div className={classes.tileRow}>

          <div className={classes.stockTile}>
            <div className={classes.tileImage}>
              <img src={IOT2050} alt="Siemens IOT2050"/>
            </div>
            <div className={classes.rightContainer}>
              <label className={classes.tileContent}>{(this.state.categoryStock.hasOwnProperty("IOT2050")) ? this.state.categoryStock.IOT2050 : ""}</label>
              <label className={classes.tileTitle}>IOT2050</label>
            </div>
          </div>

          <div className={classes.stockTile}>
            <div className={classes.tileImage}>
              <img src={R1000} alt="Seeed Studio R1000"/>
            </div>
            <div className={classes.rightContainer}>
              <label className={classes.tileContent}>{(this.state.categoryStock.hasOwnProperty("R1000")) ? this.state.categoryStock.R1000 : ""}</label>
              <label className={classes.tileTitle}>R1000</label>
            </div>
          </div>
          
          <div className={classes.statTile}>
            <label className={classes.tileContent}>{(this.state.stats.hasOwnProperty("totalUnits")) ? this.state.stats.totalUnits : ""}</label>
            <label className={classes.tileTitle}>Total items in stock</label>
          </div>

          <div className={classes.statTile}>
            <label className={classes.tileContent}>{(this.state.stats.hasOwnProperty("totalValue")) ? formatCurrency(this.state.stats.totalValue) : ""}</label>
            <label className={classes.tileTitle}>Total stock value</label>
          </div>
        </div>
        

        <div className={classes.tableContainer}>

          <DataTable
            columns={columns}
            data={this.state.filteredItems}
            noHeader={true}
            pagination={true}
            highlightOnHover={true}
            striped={true}
            defaultSortField={"category"}
            defaultSortAsc={true}
            customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}
          />
        </div>

        {this.state.showFilter && <>
          <FilterModal
            shouldShow={this.state.showFilter}
            filteredCategory={this.state.filteredCategory}
            categoryOptions={this.state.categoryOptions}
            applyFilter={(categoryID) => this.updateItems(null, categoryID)}
            closeModal={() => this.setState({ showFilter: false })}/>
          </>
        }

      </ContentContainer>
    );
  }
}


// MARK: - Helpers

function colorForStock(stock) {
  if (stock >= 5) {
    return "#27ae60"
  } else if (stock >= 3) {
    return "#f1c40f"
  } else if (stock > 0) {
    return "#e67e22"
  } else {
    return "#e74c3c"
  }
}

function formatCurrency(amount) {
  if (amount !== undefined) {
    return "€ " + parseFloat(amount).toLocaleString("de-DE")
  }
  return ""
}

function categorySort(row1, row2) {

  let a = categoryOrder.indexOf(row1.category)
  let b = categoryOrder.indexOf(row2.category)
  
  // If not found, put at the end
  if (a === -1) {
    return 1
  }

  return a > b
}

export default StockOverview;
