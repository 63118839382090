import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./bookings.css"
import Modal from "./BookingModal/BookingModal"
import FilterModal from "./FilterModal/FilterModal";
import { ReactComponent as FilterIcon } from "./FilterModal/Filter.svg";


// TODO: Filter
const resultColors = { in: "#eccc68", out: "#70a1ff" }
const columns = [
  { name: "Beschrijving", selector: "description", sortable: true, style: { fontSize: "15px" }},
  { name: "Partij", selector: "party", sortable: true, style: { fontSize: "15px" }},
  { name: "Onderdeel",  selector: "part.name", sortable: true, style: { fontSize: "14px" }},
  { name: "Resultaat", selector: "units", sortable: true, center: true, style: { fontSize: "15px" }, cell: row => {
    if (row.units) {
      return <div className={classes.resultIndicator} style={{ backgroundColor: resultColors[row.direction] }}>{(row.direction === "in" ? "+ " : "- ") + row.units + " stuk" + (row.units !== 1 ? "s" : "")}</div>
    } else {
      return ""
    }
  }},
  { name: "Type", selector: "transactionType", sortable: true, right: true, style: { fontSize: "14px" }, cell: row => {
    if (row.backordered) {
      return <div className={classes.resultIndicator} style={{ backgroundColor: "#e67e22" }}>On backorder</div>
    } else {
      return row.transactionType
    }
  }},
  { name: "Datum", selector: "timestamp", right: true, sortable: true, style: { fontSize: "14px" }, format: row => row.timestamp ? (new Date(row.timestamp * 1000).toLocaleDateString("en-GB")) : "", sortFunction: timestampSort},
]


class PartsCatalog extends Component {

  state = {
    items: [],
    filteredItems: [],
    selectedItem: null,
    showModal: false,
    showFilter: false,
    filteredPartID: "all-parts",
    partOptions: [],
  }
  
  

  componentDidMount() {

    // Fetch all bookings
    axios.get("/bookings/all").then(response => {
      if (response.data) {
        this.updateItems(response.data)
      }
    })

    // Fetch all parts in reduced form
    axios.get("/parts/all/reduced").then(response => {
      if (response.data) {
        // Create options
        let options = []
        for (let part of response.data) {
          options.push({ label: part.name, value: part._id })
        }
        this.setState({ partOptions: options })
      }
    })
  }

  displayModal = (shouldBeShown, invoice) => {
    this.setState({ showModal: shouldBeShown, selectedItem: invoice })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/bookings/all").then(response => {
          if (response.data) {
            this.updateItems(response.data)
          }
        })
      }, 500)
    }
  }

  updateItems = (items, newFilter = null) => {

    // If no items given, use state
    let unfilteredItems = items
    if (items === null) {
      unfilteredItems = this.state.items
    }

    // If no filter given, use state
    let filter = newFilter
    if (filter === null) {
      filter = this.state.filteredPartID
    }

    // Filter appropriately
    let filteredItems = unfilteredItems

    if (filter !== "all-parts") {
      filteredItems = unfilteredItems.filter(item => item.part._id === filter)
    }

    // Set items
    this.setState({ items: unfilteredItems, filteredItems: filteredItems, filteredPartID: filter })
  }

  render() {
    return (
      <ContentContainer title="Boekingen">
        <div className={classes.buttonRow}>
          <button className={(this.state.filteredPartID !== "all-parts") ? classes.filtered : classes.unfiltered } onClick={() => this.setState({ showFilter : true })}>
            {(this.state.filteredPartID !== "all-parts") ? "Filtered" : "Filter"}
            <FilterIcon fill={(this.state.filteredPartID !== "all-parts") ? "#FF6315" : "black"} className={classes.filterIcon}/>
          </button>
          <button onClick={() => this.displayModal(true, null)} className={classes.addButton}>Create booking</button>
        </div>

        <DataTable
          striped
          noHeader
          pagination
          highlightOnHover
          columns={columns}
          data={this.state.filteredItems}
          defaultSortField={"timestamp"}
          defaultSortAsc={false}
          customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}
          onRowClicked={(item) => this.displayModal(true, item)}
        />

        {this.state.showModal && <>
          <Modal
            shouldShow={this.state.showModal}
            selectedItem={this.state.selectedItem}
            partOptions={this.state.partOptions}
            closeModal={() => this.displayModal(false, null)}/>
          </>
        }
        {this.state.showFilter && <>
          <FilterModal
            shouldShow={this.state.showFilter}
            filteredPart={this.state.filteredPartID}
            partOptions={this.state.partOptions}
            applyFilter={(partID) => this.updateItems(null, partID)}
            closeModal={() => this.setState({ showFilter: false })}/>
          </>
        }

      </ContentContainer>
    );
  }
}

function timestampSort(a, b) {
  if (a.timestamp === b.timestamp) {
    return 1
  } else {
    return a.timestamp > b.timestamp
  }
}

export default PartsCatalog;
