import axios from 'axios';


var host = {}
if (window.location.hostname === "localhost") {
  host = { baseURL: "https://allorado.io:4444/api/v1.0/frontend" }
  // host = { baseURL: "http://" + window.location.hostname + ":4321/api/v1.0/frontend" }
} else {
  host = { baseURL: "https://" + window.location.hostname + ":4444/api/v1.0/frontend" }
}

const instance = axios.create(host);


// Add a 504 response interceptor (happens with database faults)
instance.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response) {
      if (error.response.status === 503) {
        if (error.response.data) {
          if (error.response.data.message) {
            alert(error.response.data.message)
          }
        }
      }
    }

    return Promise.reject(error);
});


export default instance;
