

// MARK: - Authentication types

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const PRIVILEGE_CHANGE   = "PRIVILEGE_CHANGE"
