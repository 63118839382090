import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import FileRow from "../../../configuration/shared/FileRow/FileRow";
import ButtonRow from "../../../configuration/shared/ButtonRow/ButtonRow";
import CheckboxRow from "../../../configuration/shared/CheckboxRow/CheckboxRow";
import classes from "./InvoiceModal.css"
import axios from "../../../../axios";


class InvoiceModal extends Component {

  state = {
    invoice: {},
    options: { "Intracommunity": false, "Sent to Poisson": false },
    status: { "Deliverd to customer": false, "Invoice paid": false },
    uploadFile: null,
    valuesChanged: false,
  }

  componentDidMount() {
    let invoice = this.props.selectedInvoice
    
    // Create an invoice if necessary
    if (invoice === null) {

      invoice = { 
        description: "",
        supplier: "",
        intraCommunity: false, 
        sentToAccountant: false, 
        paid: false,
        invoiceDate: new Date() / 1000,
        invoiceIndex: this.props.proposedIndex
      }
    }
    // Change dot to comma
    if (invoice.amountWithoutVAT) {
      invoice.amountWithoutVAT = invoice.amountWithoutVAT.toLocaleString("be-NL")
    }
    // Parse date
    if (invoice.invoiceDate) {
      invoice.invoiceDate = new Date(invoice.invoiceDate * 1000).toLocaleDateString("en-GB")
    }

    this.setState({ 
      invoice: {...invoice},
      options: { "Intracommunity": invoice.intraCommunity },
      status: { "Sent to Poisson": invoice.sentToAccountant, "Invoice paid": invoice.paid }
    })
  }

  deleteHandler = () => {
    // Sync to the backend
    axios.delete("/invoices/delete/" + this.state.invoice._id)
    // Close the modal view
    this.props.closeModal()
  }

  saveInvoiceHandler = () => {

    // Parse additional properties into invoice
    let invoice = this.state.invoice
    invoice.sentToAccountant = this.state.status["Sent to Poisson"]
    invoice.intraCommunity = this.state.options["Intracommunity"]
    invoice.paid = this.state.status["Invoice paid"]
    invoice.amountWithoutVAT = invoice.amountWithoutVAT ? invoice.amountWithoutVAT.replace(/,/g, '.') : null

    // Convert European date to JavaScript date
    var parts = invoice.invoiceDate ? invoice.invoiceDate.split("/") : []
    if (parts.length === 3) {
      var date = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
      invoice.invoiceDate = date.getTime() / 1000
    } else {
      invoice.invoiceDate = 0
    }

    // Sync to the backend
    if (this.state.valuesChanged) {
      axios.post("/invoices/update/aankoop", this.state.invoice)
    }

    // Close the modal view
    this.props.closeModal()
  }

  uploadPDF = (event, thisRef) => {

    // Read the file's contents
    var reader = new FileReader();
    reader.addEventListener("load", function() {
      // Save uploaded PDF
      let pdfContent = reader.result.split("base64,")[1]
      thisRef.updateProperty("pdfContent", pdfContent)
    })
    reader.readAsDataURL(event.target.files[0])
  }

  downloadPDFhandler = () => {

    // Request the PDF from the backend first (lazy fetch)
    axios.get("/invoices/pdf/" + this.state.invoice._id).then(response => {
      if (response.data) {

        if (response.data.pdfContent) {
          // Download PDF file
          const fileName = this.state.invoice.invoiceIndex + " - " + this.state.invoice.supplier + " " + this.state.invoice.invoiceDate.replace(/\//g, '-') + ".pdf"
          const href = "data:application/pdf;base64," + response.data.pdfContent
          const link = document.createElement("a")
          link.href = href
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          return 

        } 
      }
      alert("No PDF file was attached to this invoice entry.")

    }).catch((err) => {
      alert("Could not download invoice: " + err.message)
    })
  }

  updateProperty(property, value) {
    let invoice = {...this.state.invoice}
    invoice[property] = value
    this.setState({ invoice: invoice, valuesChanged: true })
  }


  render() {

    let createMode = (this.props.selectedInvoice === null)

    // Calculate total amount with VAT
    let showTotal = false
    let totalAmount = 0
    if (this.state.invoice.amountWithoutVAT !== undefined) {
      let numericWithoutVAT = this.state.invoice.amountWithoutVAT.replace(/,/g, '.')
      if (!isNaN(numericWithoutVAT)) {
        if (this.state.options.Intracommunity) {
          totalAmount = parseFloat(numericWithoutVAT)
        } else {
          totalAmount = Math.round(parseFloat(numericWithoutVAT) * 121) / 100
        }
        showTotal = true
      } else {
        showTotal = false
      }
    }


    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h2>{createMode ? "Upload new purchase" : "Edit purchase"}</h2>
        
        <InputRow
          title="Supplier"
          enabled
          ultrawide
          firstRow
          value={this.state.invoice.supplier ?? ""}
          placeholder={"E.g. Siemens"}
          onChange={(event) => this.updateProperty("supplier", event.target.value)}/>
        <InputRow
          title="Description"
          enabled
          ultrawide
          value={this.state.invoice.description ?? ""}
          placeholder={"E.g. IOT2050 hardware"}
          onChange={(event) => this.updateProperty("description", event.target.value)}/>
        <InputRow
          title="Invoice number"
          enabled
          ultrawide
          value={this.state.invoice.invoiceIndex ?? ""}
          placeholder={"E.g. 5"}
          onChange={(event) => this.updateProperty("invoiceIndex", event.target.value)}/>
        <InputRow
          title="Invoice date"
          enabled
          ultrawide
          value={this.state.invoice.invoiceDate ?? ""}
          placeholder={"E.g. 15/01/2024"}
          onChange={(event) => this.updateProperty("invoiceDate", event.target.value)}/>
        <InputRow
          title="Amount excl. VAT"
          enabled
          ultrawide
          value={this.state.invoice.amountWithoutVAT ?? ""}
          placeholder={"Amount"}
          onChange={(event) => this.updateProperty("amountWithoutVAT", event.target.value)}/>
        {showTotal &&
        <InputRow
          title="Total invoice amount"
          enabled={false}
          ultrawide
          value={"€ " + totalAmount.toLocaleString("de-DE")}/>}
        <CheckboxRow
          title="Options"
          options={this.state.options}
          optionChanged={(option, checked) => {
            let options = {...this.state.options}
            options[option] = checked
            this.setState({ options: options, valuesChanged: true })
          }}/>
        <CheckboxRow
          title="Status"
          options={this.state.status}
          optionChanged={(option, checked) => {
            let status = {...this.state.status}
            status[option] = checked
            this.setState({ status: status, valuesChanged: true })
          }}/> 

        {createMode ?
        <FileRow
          title="Upload invoice PDF"
          body={"Choose .pdf file"}
          onClick={(pdf) => this.uploadPDF(pdf, this)}
          extension=".pdf"/> :
          
        <ButtonRow
          title="PDF invoice"
          ultrawide
          body="Download .pdf file"
          onClick={this.downloadPDFhandler}/>}

        <div className={classes.buttonRow}>
          {!createMode && <button className={classes.deleteButton} onClick={this.deleteHandler}>Delete</button>}
          <button className={(this.state.valuesChanged || createMode) ? classes.saveButton : classes.closeButton} onClick={this.saveInvoiceHandler}>{createMode ? "Upload" : this.state.valuesChanged ? "Save" : "Close"}</button>
        </div>
      </Modal>
    );
  }
}

export default InvoiceModal;
