import React from "react";
import classes from "./ImageInfo.css"

const messageInfo = (props) => {
  return (
    <div className={classes.container}>

      <table className={classes.propertiesTable}>
          <tbody>
            <tr>
              <td><label className={classes.tableLabel}>Version string</label></td>
              <td><label className={classes.tableValue}>{props.data.versionString}</label></td>
            </tr>
            <tr>
              <td><label className={classes.tableLabel}>Version number</label></td>
              <td><label className={classes.tableValue}>{props.data.versionNumber}</label></td>
            </tr>
            <tr>
              <td><label className={classes.tableLabel}>Release date</label></td>
              <td><label className={classes.tableValue}>{props.data.releaseDate}</label></td>
            </tr>
            <tr>
              <td><label className={classes.tableLabel}>Platform</label></td>
              <td><label className={classes.tableValue}>{props.data.platform}</label></td>
            </tr>
            <tr>
              <td><label className={classes.tableLabel}>Kernel version</label></td>
              <td><label className={classes.tableValue}>{props.data.kernelVersion}</label></td>
            </tr>
            <tr>
              <td><label className={classes.tableLabel}>Image size</label></td>
              <td><label className={classes.tableValue}>{props.data.imageSize}</label></td>
            </tr>
          </tbody>
        </table>

        <div className={classes.featureContainer}>
          <p>Features:</p>
          <ul>
            {props.data.features.map(feature => <li>{feature}</li>)}
          </ul>
        </div>

        <div className={classes.actionContainer}>
          <button onClick={() => props.download(props.data)}>Download .json file</button>
          <button onClick={() => props.delete(props.data)}>Delete this entry</button>
          <button onClick={() => props.mark(props.data)}>Mark as {props.data.releaseType === "golden master" ? "alpha" : (props.data.releaseType === "alpha" ? "beta" : "GM")}</button>
        </div>
      </div>
  )
}

export default messageInfo
