import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./purchases.css"
import InvoiceModal from "./InvoiceModal/InvoiceModal"

const columns = [
  { name: "Datum", selector: "invoiceDate", sortable: true, left: true, format: row => row.invoiceDate ? (new Date(row.invoiceDate * 1000).toLocaleDateString("en-GB")) : "", style: { fontSize: "14px" }, sortFunction: timestampSort },
  { name: "Leverancier", selector: "supplier", sortable: true, style: { fontSize: "15px" } },
  { name: "Totaal bedrag", selector: row => getTotalAmount(row), sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Beschrijving", selector: "description", sortable: false, style: { fontSize: "15px" } },
]

class Purchases extends Component {

  state = {
    invoices: [],
    selectedInvoice: null,
    showInvoiceModal: false,
    nextIndex: 1,
  }


  componentDidMount() {

    // Fetch all invoices
    axios.get("/invoices/all/aankoop").then(response => {
      if (response.data) {
        this.setState({ invoices: response.data, nextIndex: calculateNextIndex(response.data) })
      }
    })
  }

  displayModal = (shouldBeShown, invoice) => {
    this.setState({ showInvoiceModal: shouldBeShown, selectedInvoice: invoice })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/invoices/all/aankoop").then(response => {
          if (response.data) {
            this.setState({ invoices: response.data, nextIndex: calculateNextIndex(response.data) })
          }
        })
      }, 500)
    }
  }

  render() {
    return (
      <ContentContainer title="Aankopen">
        <button onClick={() => this.displayModal(true, null)} className={classes.addButton}>Upload manually</button>

        <DataTable
          columns={columns}
          data={this.state.invoices}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"invoiceDate"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          onRowClicked={(invoice) => this.displayModal(true, invoice)}
        />


        {this.state.showInvoiceModal && <>
          <InvoiceModal
            proposedIndex={this.state.nextIndex}
            shouldShow={this.state.showInvoiceModal}
            selectedInvoice={this.state.selectedInvoice}
            closeModal={() => this.displayModal(false, null)}/>
          </>
        }

      </ContentContainer>
    );
  }
}


// MARK: - Helpers

function getTotalAmount(invoice) {

  if (invoice.amountWithoutVAT !== undefined) {
    if (invoice.intraCommunity) {
      return "€ " + parseFloat(invoice.amountWithoutVAT).toLocaleString("de-DE")
    } else {
      return "€ " + (Math.round(parseFloat(invoice.amountWithoutVAT) * 121) / 100).toLocaleString("de-DE")
    }
  }
  return ""
}

function calculateNextIndex(invoices) {
  let year = (new Date()).getFullYear().toString()
  let invoicesCurrentYear = invoices.filter((invoice) => invoice.financialYear === year)
  return invoicesCurrentYear.length + 1
}

function timestampSort(a, b) {
  if (a.invoiceDate === b.invoiceDate) {
    return 1
  } else {
    return a.invoiceDate > b.invoiceDate
  }
}

export default Purchases;
