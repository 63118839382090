import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./invoices.css"
import InvoiceModal from "./InvoiceModal/InvoiceModal"

const columns = [
  { name: "Reference", selector: "invoiceReference", sortable: true, style: { fontSize: "15px" }, sortFunction: referenceSort },
  { name: "Customer", selector: row => (typeof(row.customer) === "object") ? row.customer.name : "", sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Due date", selector: "dueDate", sortable: true, left: true, format: row => row.dueDate ? (new Date(row.dueDate * 1000).toLocaleDateString("en-GB")) : "", style: { fontSize: "14px" }},
  { name: "Total amount", selector: row => getTotalAmount(row), sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Status", selector: row => getStateForInvoice(row), sortable: true, right: true, cell: row => {
    let state = getStateForInvoice(row)
    let color = getColorForState(state)
    return <div className={classes.statusIndicator} style={{backgroundColor: color}}>{state}</div>
  }},
]

class Invoices extends Component {

  state = {
    invoices: [],
    selectedInvoice: null,
    showInvoiceModal: false,
    customersOptions: [],
    nextIndex: 1
  }


  componentDidMount() {

    // Fetch all invoices
    axios.get("/invoices/all/verkoop").then(response => {
      if (response.data) {
        this.setState({ invoices: response.data, nextIndex: calculateNextIndex(response.data) })
      }
    })

    // Customers
    axios.get("/customers/allReduced").then(response => {
      if (response.data) {
        // Map into Select component
        const select = response.data.map((el) => {
          return { label: el.name, value: el._id }
        })
        this.setState({ customersOptions: select})
      }
    })
  }

  displayModal = (shouldBeShown, invoice) => {
    this.setState({ showInvoiceModal: shouldBeShown, selectedInvoice: invoice })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/invoices/all/verkoop").then(response => {
          if (response.data) {
            this.setState({ invoices: response.data, nextIndex: calculateNextIndex(response.data) })
          }
        })
      }, 500)
    }
  }

  render() {
    return (
      <ContentContainer title="Verkopen">
        <button onClick={() => this.displayModal(true, null)} className={classes.addButton}>Upload manually</button>
        <button onClick={() => alert("Generation not yet implemented, please create in Excel.")} className={classes.addButton}>Generate invoice</button>

        <DataTable
          columns={columns}
          data={this.state.invoices}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"invoiceReference"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          onRowClicked={(invoice) => this.displayModal(true, invoice)}
        />


        {this.state.showInvoiceModal && <>
          <InvoiceModal
            proposedIndex={this.state.nextIndex}
            customersOptions={this.state.customersOptions}
            shouldShow={this.state.showInvoiceModal}
            selectedInvoice={this.state.selectedInvoice}
            closeModal={() => this.displayModal(false, null)}/>
          </>
        }

      </ContentContainer>
    );
  }
}


// MARK: - Helpers

function getStateForInvoice(invoice) {
  if (invoice.paid) {
    return "Paid"
  } else {
    if (invoice.sentToCustomer) {
      if (new Date(invoice.dueDate * 1000) < new Date()) {
        return "Unpaid & overdue"
      } else {
        return "Waiting for payment"
      }
    } else {
      return "To be sent out"
    }
  }
}

function getColorForState(state) {
  switch (state) {
    case "Paid": return "#27ae60";
    case "To be sent out": return "#f1c40f";
    case "Unpaid & overdue": return "#e74c3c";
    case "Waiting for payment": return "#95a5a6";
    default: return "";
  }
}

function getTotalAmount(invoice) {

  if (invoice.amountWithoutVAT !== undefined) {
    if (invoice.intraCommunity) {
      return "€ " + parseFloat(invoice.amountWithoutVAT).toLocaleString("de-DE")
    } else {
      return "€ " + (Math.round(parseFloat(invoice.amountWithoutVAT) * 121) / 100).toLocaleString("de-DE")
    }
  }
  return ""
}

function referenceSort(a, b) {
  let refA = a.invoiceReference.split(" - ")
  let refB = b.invoiceReference.split(" - ")

  if (refA.length > 1 && refB.length > 1) {
    let yearA = refA[1].substring(0,2)
    let yearB = refB[1].substring(0,2)
    // If in different years, sort by year
    if (yearA !== yearB) {
      return yearA > yearB
    } else {
      // Else sort by invoice number in the same year
      return parseInt(refA[0]) > parseInt(refB[0])
    }
  } else {
    return a.invoiceReference < b.invoiceReference
  }
}

function calculateNextIndex(invoices) {
  let year = (new Date()).getFullYear().toString()
  let invoicesCurrentYear = invoices.filter((invoice) => invoice.financialYear === year)
  return invoicesCurrentYear.length + 1
}

export default Invoices;
