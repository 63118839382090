
import React, { Component } from "react"
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer"
import DataTable from "react-data-table-component"
import ImageInfo from "./ImageInfo/ImageInfo"
import axios from "../../../axios"
import classes from "./images.css"

import R1000 from "../../images/R1000.png"
import IOT2050 from "../../images/IOT2050.png"

const releaseColors = { alpha: "#fad390", beta: "#6a89cc", "golden master": "#27ae60" }
const columns = [
  { name: "Version", selector: "versionNumber", sortable: true, left: true, style: { fontSize: "15px" }},
  { name: "Platform", selector: "platform", sortable: true, style: { fontSize: "14px" }},
  { name: "Release date", selector: "releaseDate", sortable: false, grow: 2, style: { fontSize: "13px" }},
  { name: "Release type", selector: "releaseType", sortable: true, grow: 2, cell: row => {
    if (row.releaseType) {
      return <div className={classes.statusIndicator} style={{ backgroundColor: releaseColors[row.releaseType] }}>{row.releaseType.charAt(0).toUpperCase() + row.releaseType.slice(1)}</div>
    } else {
      return ""
    }
  }},
]

class Images extends Component {

  state = {
    selectedPlatform: "",
    builds: [],
    filteredBuilds: [],
  }


  componentDidMount() {
    axios.get("/images/all")
    .then(response => {
      if (response.data) {
        this.updateBuilds(response.data)
      }
    })
  }

  // MARK: - Apply filtering

  updateBuilds = (array, selectedPlatform) => {

    // Use specified filter if present
    let filter = this.state.selectedPlatform
    if (selectedPlatform !== undefined) {
      filter = selectedPlatform
    }

    // Filter if any applied
    let filteredArray = array
    if (filter !== "") {
      filteredArray = array.filter(build => build.platform === filter)
    }

    this.setState({ builds: array, filteredBuilds: filteredArray, selectedPlatform: filter })
  }


  // MARK: - Selection helper

  selectPlatform = (chosenPlatform) => {

    // If the currently selected platform is clicked,
    // unselect it again
    let newSelection = chosenPlatform
    
    if (newSelection === this.state.selectedPlatform) {
      newSelection = ""
    }
    this.updateBuilds(this.state.builds, newSelection)
  }

 
  // MARK: - Upload helper

  uploadBuildInfo = (event, thisRef) => {

    // Read the file's contents
    var reader = new FileReader();

    reader.addEventListener("load", function() {

      // Check if result is valid JSON
      let buildInfo = {}
      try {
        buildInfo = JSON.parse(reader.result)
      } catch (e) {
        alert("The uploaded file does not contain valid JSON.")
        return
      }

      // Upload to the server
      axios.post("/images/upload", buildInfo)

      // Add it locally
      thisRef.updateBuilds([buildInfo, ...thisRef.state.builds])

    }, false)

    // Upload the file
    reader.readAsText(event.target.files[0])
  }


  // MARK: - Detail view actions

  downloadJSONfile = (build) => {

    // Prepare content
    let fileContent = {...build}
    delete fileContent._id
    delete fileContent.__v

    // Download file
    const fileName =  "BuildInfo-" + build.versionNumber + "-" + build.platform + ".json"
    const blob = new Blob([JSON.stringify(fileContent, null, 2)], { type:"application/json" })
    const href = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = href
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  deleteBuild = (build) => {
    axios.delete("/images/delete/" + build._id)
  }

  changeReleaseType = (build) => {
    // Cycle through release types
    let updatedBuild = build
    switch (build.releaseType) {
      case "alpha": updatedBuild.releaseType = "beta"; break;
      case "beta": updatedBuild.releaseType = "golden master"; break;
      case "golden master": updatedBuild.releaseType = "alpha"; break;
      default: break;
    }
    axios.post("/images/update", updatedBuild)
  }



  render() {
    return (
      <ContentContainer title="Software images">

        <div className={classes.uploadRow}>
          <input
            type="file"
            id="fileInput"
            accept={".json"}
            onChange={(event) => this.uploadBuildInfo(event, this)}/>
          <label htmlFor="fileInput">Upload</label>
        </div>

        <div className={classes.platformRow}>

          <div className={classes.platformTile + " " + (this.state.selectedPlatform === "R1000" ? classes.selected : "")} onClick={() => this.selectPlatform("R1000")}>
            <div className={classes.platformImage}>
              <img src={R1000} alt="Seeed Studio R1000"/>
            </div>
            <label className={classes.platformName}>R1000 builds</label>
          </div>

          <div className={classes.platformTile + " " + (this.state.selectedPlatform === "IOT2050" ? classes.selected : "")} onClick={() => this.selectPlatform("IOT2050")}>
            <div className={classes.platformImage}>
              <img src={IOT2050} alt="Siemens IOT2050"/>
            </div>
            <label className={classes.platformName}>IOT2050 builds</label>
          </div>
          
        </div>
        
        <div className={classes.tableContainer}>
          <DataTable
            columns={columns}
            data={this.state.filteredBuilds}
            noHeader
            pagination
            highlightOnHover
            expandOnRowClicked
            striped
            defaultSortField={"versionNumber"}
            defaultSortAsc={false}
            customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}

            expandableRows={true}
            expandableRowsComponent={<ImageInfo download={this.downloadJSONfile} delete={this.deleteBuild} mark={this.changeReleaseType}/>}
          />
        </div>
    
      </ContentContainer>
    )
  }
}

export default Images;
