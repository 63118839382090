import React from "react";

import PanelSection from "./PanelSection/PanelSection";
import PanelItem from "./PanelItem/PanelItem";
import classes from "./NavigationPanel.css"

const navigationPanel = (props) => {

  return (
    <div className={classes.container}>

      <div className={classes.subcontainer}>
        {props.enableAdministation &&
        <PanelSection title="Administratief" normallyOpen={window.location.pathname.includes("administration")}>
          <PanelItem label="Klanten" page="/administration/customers"/>
          <PanelItem label="Offertes" page="/administration/offers" toBeImplemented/>
          <PanelItem label="Bestellingen" page="/administration/orders" toBeImplemented/>
          <PanelItem label="Verkopen" page="/administration/invoices"/>
          <PanelItem label="Aankopen" page="/administration/purchases"/>
          <PanelItem label="Financieel" page="/administration/financial"/>
          <PanelItem label="Uitbetaling" page="/administration/cashout"/>
        </PanelSection>}

        {props.enableAdministation &&
        <PanelSection title="Sales dashboard" normallyOpen={window.location.pathname.includes("sales")}>
          <PanelItem label="Overview" page="/sales/overview"/>
          <PanelItem label="Customer relations" page="/sales/crm"/>
          <PanelItem label="Leads" page="/sales/leads"/>
        </PanelSection>}

        {props.enableStock &&
        <PanelSection title="Stockbeheer" normallyOpen={window.location.pathname.includes("stock")}>
          <PanelItem label="Overzicht" page="/stock/overview"/>
          <PanelItem label="Boekingen" page="/stock/bookings"/>
          <PanelItem label="Parts catalog" page="/stock/catalog"/>
          <PanelItem label="Parts in use" page="/stock/in-use"/>
        </PanelSection>}

        {props.enableDeployments &&
        <PanelSection title="Deployments" normallyOpen={window.location.pathname.includes("deployments")}>
          <PanelItem label="Edit gateways" page="/deployments/edit"/>
          <PanelItem label="Licenses" page="/deployments/licenses"/>
          <PanelItem label="Usage statistics" page="/deployments/statistics"/>
        </PanelSection>}

        <PanelSection title="Infrastructure" normallyOpen>
          <PanelItem label="Server status" page="/infrastructure/server"/>
          <PanelItem label="Decoder software" page="/infrastructure/decoders"/>
          <PanelItem label="Software images" page="/infrastructure/images"/>
        </PanelSection>
      </div>

      <p className={classes.versionNumber}>
        Version 1.4.1
      </p>
    </div>
  );
}

export default navigationPanel;
