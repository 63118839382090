
export function calculateStock(parts, bookings) {

  // Prepare
  let categories = {}
  let partRows = []
  let partsDict = {}
  let stock = {} // part_id : count
  let backorder = {} // part_id : count
  let debug = {} // part_id : count
  let categoryStock = {} // category : count
  let totalUnits = 0
  let totalValue = 0

  // Convert parts array to dictionary for easy lookup
  for (let part of parts) {
    partsDict[part._id] = part
  }

  // Calculate net result of bookings
  for (let booking of bookings) {
    if (booking.part) {
      
      // Extract results
      let partID = booking.part._id
      let result = booking.units * (booking.direction === "in" ? 1 : -1)
      let category = partsDict[partID].category
      // Create category list
      categories[category] = 0
      // Make sure an entry exists
      if (!stock.hasOwnProperty(partID)) { stock[partID] = 0 }
      if (!backorder.hasOwnProperty(partID)) { backorder[partID] = 0 }
      if (!debug.hasOwnProperty(partID)) { debug[partID] = 0 }
      if (!categoryStock.hasOwnProperty(category)) { categoryStock[category] = 0 }

      // Apply to stock
      if (booking.backordered === true) {
        backorder[partID] = backorder[partID] + result
      } else {
        stock[partID] = stock[partID] + result
        categoryStock[category] = categoryStock[category] + result
      }
    }
  }

  // For parts with bookings, create a partRow
  partRows = Object.keys(stock).map(partID => { 
    let part = partsDict[partID]
    let unitsInUse = partsDict[partID].unitsInUse || 0

    return { ...part, stock: stock[partID] - unitsInUse, backorder: backorder[partID], value: (stock[partID] + backorder[partID]) * part.purchasePrice }
  })

  // Calculate total units and total value
  for (let row of partRows) {
    totalUnits += row.stock

    // Only count value of certain categories
    if (row.category !== "LoRa devices" && row.category !== "Other") {
      totalValue += row.value
    }
  }

  // Construct categories
  categories = Object.keys(categories).map(category => {return { value: category, label: category }})

  return { categories: categories, stats: { totalUnits, totalValue }, partRows: partRows, categoryStock: categoryStock }
}