import React from "react";
import Collapsible from "react-collapsible";
import classes from "./PanelSection.css"
import Arrow from "./arrow.svg"

const panelSection = (props) => (
  <Collapsible trigger={[<span className={classes.title}>{props.title}</span>, <img className={classes.arrow} src={Arrow} alt=""/>]}
    transitionTime={300}
    key={props.title}
    className={classes.collapsibleItemClosed}
    openedClassName={classes.collapsibleItemOpen}
    triggerClassName={classes.header}
    open={props.normallyOpen === true}
    triggerOpenedClassName={classes.header}>
    <div className={classes.collapsibleContent}>
      <div className={classes.itemContainer}>
        {props.children}
      </div>
    </div>
  </Collapsible>
);

export default panelSection;
