import React from "react";
import ReactSelect from "react-select";

function select({ defaultValue, value, options, ...otherProps }) {
  return (
    <ReactSelect
      defaultValue={
        defaultValue && {
          value: defaultValue,
          label: options.find(o => o.value === defaultValue).label,
        }
      }
      value={
        value && {
          value: value,
          label: options.find(o => o.value === value).label,
        }
      }
      options={options}
      {...otherProps}
    />
  );
}

export default select;
