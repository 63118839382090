import * as actionTypes from "./actionTypes";
import * as privilegeActions from "./privilege"
import axios from "../../axios";


// Internal actionCreators

const authenticationStarted = () => {
  return { type: actionTypes.AUTH_START };
}

const authenticationSuccess = (token, username) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    username: username
  };
}

const authenticationFail = (error) => {
  return { type: actionTypes.AUTH_FAIL, error: error };
}



// Publicly accessible actionCreators

export const authenticate = (username, password) => {
  return (dispatch) => {

    // Notify about start
    dispatch(authenticationStarted());

    // Perform async authentication call
    axios.post("/auth/login", {username: username, password: password}, { headers: {"Access-Control-Expose-Headers": "Www-Authenticate"}})
      .then((response) => {

        // Successful authencation
        // Save token locally
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("tokenExpiration", response.data.tokenExpiration);

        // Set the token to axios
        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;

        // Let the rest of the application know
        dispatch(authenticationSuccess(response.data.token, response.data.username));

        // Check frontend privileges
        axios.get("/auth/privileges")
          .then(response => {
            dispatch(privilegeActions.updatePrivileges(response.data));
          })

        // Calculate token expiration time
        const currentTime = Math.floor((new Date()).getTime() / 1000);
        const tokenTimeLeft = response.data.tokenExpiration - currentTime;

        // Handle token expiry
        setTimeout(() => {
          dispatch(logout());
        }, tokenTimeLeft * 1000);
      })
      .catch((err) => {

        if (err.response) {
          switch (err.response.status) {
            case 401:
              if (err.response.headers["www-authenticate"] === "db-unreachable") {
                dispatch(authenticationFail("Internal database is not running."));
              } else if (err.response.headers["www-authenticate"] === "db-error") {
                dispatch(authenticationFail("Internal database error."));
              } else {
                dispatch(authenticationFail("Incorrect email address or password."));
              }
              break;
            default: dispatch(authenticationFail("Authentication failed with code " + err.response.status + ".")); break;
          }
        } else if (!err.status) {
          // Handle network error (backend not running)
          dispatch(authenticationFail("Console backend currently unavailable."))
        } else {
          dispatch(authenticationFail("Authentication failed. Please try again."))
        }
      })
  }
}

export const attemptSessionRestoration = () => {
  return (dispatch) => {

    // Retrieve from localStorage
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    // Token expiration
    const currentTime = Math.floor((new Date()).getTime() / 1000);
    const tokenTimeLeft = tokenExpiration - currentTime;

    // Check if a token is present
    if (token) {
      // TODO: Check token validity here

      if (tokenTimeLeft > 0) {

        // Set the token to axios
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        dispatch(authenticationSuccess(token, username));

        // Handle token expiry
        setTimeout(() => {
          dispatch(logout());
        }, tokenTimeLeft * 1000);

      } else {
        dispatch(logout());
      }
    } else {
      dispatch(logout());
    }
  }
}

export const logout = () => {

  // Clear localStorage
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  localStorage.removeItem("tokenExpiration");

  // Clear axios token
  axios.defaults.headers.common["Authorization"] = null;

  // Return action
  return { type: actionTypes.AUTH_LOGOUT };
}
