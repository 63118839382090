import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import InfoRow from "../../../configuration/shared/InfoRow/InfoRow";
import classes from "./GatewayModal.css"
import axios from "../../../../axios";


class GatewayModal extends Component {

  state = {
    gateway: {},
  }

  modelOptions = [{ label: "IOT2050 Advanced FS:09", value: "fs09"}, { label: "IOT2050 Advanced FS:08", value: "fs08"}, { label: "IOT2050 Advanced FS:07", value: "fs07"}, { label: "IOT2050 Advanced FS:05", value: "fs05"}, { label: "IOT2050 Advanced FS:04", value: "fs04"}, { label: "IOT2050 Advanced FS:02", value: "fs02"}, { label: "IOT2050 Basic", value: "fs03"}, { label: "Seeed R1025-10", value: "r1025-10"}, { label: "Seeed R1024-10", value: "r1024-10"}, { label: "Seeed R1013-10", value: "r1013-10"}, { label: "Advantech UNO-127", value: "uno127"}]
  pcieOptions = [{ label: "None", value: "None"}, { label: "N-Fuse SX1301", value: "N-Fuse SX1301"}, { label: "Seeed WM1302", value: "Seeed WM1302"}, { label: "N-Fuse SX1308", value: "N-Fuse SX1308"}, { label: "RAK SX1302", value: "RAK SX1302"}]


  componentDidMount() {
    this.setState({ ...this.props.selectedGateway })
  }

  saveGatewayToServer = () => {

    // Instead of customer object, fill in customer id
    var gateway = this.state
    gateway.customer = gateway.customer._id
    // Sync to the backend
    axios.post("/fleet/update", gateway);
    // Close the modal view
    this.props.closeModal()
  }

  cancelEditHandler = () => {
    this.props.closeModal()
  } 


  render() {

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h3>Edit gateway</h3>
        <SelectRow
          title="Customer"
          firstRow
          ultrawide
          value={this.state.customer ? (this.state.customer._id ?? "") : ""}
          onChange={(newValue) => this.setState({ customer: { _id: newValue.value } })}
          options={this.props.customers}/>
        <InputRow
          title="Friendly name"
          enabled
          ultrawide
          value={this.state.trivialName ?? ""}
          placeholder={"E.g. Solar plant 5"}
          onChange={(event) => this.setState({ trivialName: event.target.value })}/>
        <InputRow
          title="Location"
          enabled
          ultrawide
          value={this.state.location ?? ""}
          placeholder={"E.g. Brussels, BE"}
          onChange={(event) => this.setState({ location: event.target.value })}/>
        <InputRow
          title="CPU UUID"
          ultrawide
          enabled={!("license" in this.state)}
          value={this.state.cpuUUID ?? ""}
          placeholder={"CPU UUID"}
          onChange={(event) => this.setState({ cpuUUID: event.target.value.toUpperCase() })}/>
        <InputRow
          title="Serial number"
          enabled
          ultrawide
          value={this.state.serialNumber ?? ""}
          onChange={(event) => this.setState({ serialNumber: event.target.value.toUpperCase() })}/>
        <SelectRow
          title="Model"
          ultrawide
          value={this.state.model ?? ""}
          onChange={(newValue) => this.setState({ model: newValue.value })}
          options={this.modelOptions}/>
        <SelectRow
          title="PCIe slot"
          ultrawide
          value={this.state.pcieExpansion ?? ""}
          onChange={(newValue) => this.setState({ pcieExpansion: newValue.value })}
          options={this.pcieOptions}/>
        <InputRow
          title="Firmware"
          enabled
          ultrawide
          value={this.state.versionNumber ?? ""}
          placeholder={"E.g. 1.1.4"}
          onChange={(event) => this.setState({ versionNumber: event.target.value })}/>
        <InfoRow
          title="Associated license"
          ultrawide
          success={(this.state.license) ? null : false}
          body={(this.state.license) ? "Valid until " + this.formatDate(this.state.license.endDate)  : "No license generated"}/>

        <div className={classes.buttonRow}>
          <button className={classes.cancelButton} onClick={this.cancelEditHandler}>Cancel</button>
          <button className={classes.closeButton} onClick={this.saveGatewayToServer}>Save</button>
        </div>
      </Modal>
    );
  }

  // MARK: - Date format helper
  formatDate = (epoch) => {
    // Create date
    var date = new Date(0)
    date.setUTCSeconds(epoch)
    // Format properly
    return date.toLocaleDateString("en-us", { year: "numeric", month: "long", day: "numeric"})
  }
}

export default GatewayModal;
