import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import LicenseModal from "./LicenseModal/LicenseModal"
import GenerateLicense from "./GenerateLicense/GenerateLicense"
import axios from "../../../axios"
import classes from "./licenses.css"



class Fleet extends Component {

  state = {
    licenses: [],
    showLicenseModal: false,
    showGenerateModal: false,
    selectedLicense: {},
  }

  columns = [
    { name: "Friendly name", selector: "gateway.trivialName", sortable: true, left: true, style: { fontSize: "14px" }},
    { name: "Serial number", selector: "gateway.serialNumber", sortable: true, left: true, style: { fontSize: "14px" }},
    { name: "Start date", selector: "startDate", sortable: true, left: true, style: { fontSize: "14px" },
        format: (row, index) => { return this.formatDate(row.startDate) }},
    { name: "End date", selector: "endDate", sortable: true, left: true, style: { fontSize: "15px" },
        format: (row, index) => { return this.formatDate(row.endDate) }},
  ]

  componentDidMount() {
    axios.get("/license/all")
      .then(response => {
        if (response.data) {
          this.setState({ licenses: response.data })
        }
      })
  }

  displayLicenseModal = (shouldBeShown, license) => {
    this.setState({ showLicenseModal: shouldBeShown, selectedLicense: license })
  }

  closeGeneratorModal = () => {
    this.setState({ showGenerateModal: false })
    // Update license list
    axios.get("/license/all")
      .then(response => {
        if (response.data) {
          this.setState({ licenses: response.data })
        }
      })
  }


  render() {
    return (
      <ContentContainer title="Licenses">
        <button onClick={() => this.setState({ showGenerateModal : true })} className={classes.addButton}>Generate license</button>
        <DataTable
          columns={this.columns}
          data={this.state.licenses}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"serialNumber"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          onRowClicked={(license) => this.displayLicenseModal(true, license)}
        />

        {this.state.showLicenseModal && <>
        <LicenseModal
          shouldShow={this.state.showLicenseModal}
          license={this.state.selectedLicense}
          formatDate={this.formatDate}
          closeModal={() => this.displayLicenseModal(false, {})}/>
        </>}

        {this.state.showGenerateModal && <>
        <GenerateLicense
          shouldShow={this.state.showGenerateModal}
          formatDate={this.formatDate}
          closeModal={this.closeGeneratorModal}/>
        </>}

      </ContentContainer>
    );
  }


  // MARK: - Date format helper
  formatDate = (epoch) => {
    // Create date
    var date = new Date(0)
    date.setUTCSeconds(epoch)
    // Format properly
    return date.toLocaleDateString("en-us", { year: "numeric", month: "long", day: "numeric"})
  }
}

export default Fleet;
