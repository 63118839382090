import React from "react";
import classes from "./InfoRow.css"


const infoRow = (props) => {

  const outerClasses = [classes.rowContainer];
  if (props.firstRow === true) {
    outerClasses.push(classes.isFirstRow);
  }

  return (
    <div className={outerClasses.join(" ")}>
      <p className={classes.title}>{props.title}</p>
      <p className={classes.body}>{props.body}</p>
    </div>
  );
};


export default infoRow;
