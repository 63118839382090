import * as actionTypes from "../actions/actionTypes";

// MARK: - State structure
const initialState = {
  hasAdministrationPrivilege: false,
  hasStockPrivilege: false,
  hasDeploymentPrivilege: false,
}


// MARK: - Reducer
const privilegeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRIVILEGE_CHANGE: 
      return { 
        hasAdministrationPrivilege: action.hasAdministrationPrivilege, 
        hasStockPrivilege: action.hasStockPrivilege,
        hasDeploymentPrivilege: action.hasDeploymentPrivilege 
      }
    default: return state;
  }
}

export default privilegeReducer
