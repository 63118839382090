import React from "react";
import classes from "./PanelItem.css";
import { NavLink } from "react-router-dom";


const panelItem = (props) => {

  let link = <NavLink className={classes.label} activeClassName={classes.selected} to={props.page}>{props.label}</NavLink>

  if (props.toBeImplemented) {
    link = <NavLink className={classes.label + " " + classes.toBeImplemented} activeClassName={classes.selected} to={props.page}>{props.label}</NavLink>
  }
  return (<p>{link}</p>);
}

export default panelItem;
