import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import FileRow from "../../../configuration/shared/FileRow/FileRow";
import classes from "./SoftwareModal.css"
import axios from "../../../../axios";


class SoftwareModal extends Component {

  state = {
    chosenFile: null
  }

  componentDidMount() {
    this.setState({ ...this.props.selectedSoftware })
  }

  saveSoftwareToServer = () => {

    if (this.state.chosenFile === null && this.props.addMode) {
      alert("Please upload a file.")
      return;
    }


    if (this.props.addMode) {

      // Upload the file
      // Read the file's contents
      let version = this.state.versionNumber
      let thisRef = this
      let reader = new FileReader();
      reader.addEventListener("load", function() {

        // Process result
        let packageContents = reader.result.split("base64,")[1];

        // Upload the base64-encoded contents to the backend
        axios.post("/decoders/uploadDeb", { dpkgPackage: packageContents, fileName: "allorado-decoders-" + version + ".deb" })
        .then(() => {
          
          // Only install entry into DB if successfully uploaded
          axios.post("/decoders/updateVersion", thisRef.state);
          
          // Close the modal
          thisRef.props.closeModal()

        }).catch(err => {
          let message = err.toString()
          if (err.response) {
            if (typeof err.response.data === "object") {
              message = message + "\r\n" + JSON.stringify(err.response.data)
            }
          }
          alert(message)
        })

      }, false)
      reader.readAsDataURL(this.state.chosenFile)

    } else {
      this.props.closeModal()
    }
  }

  deleteVersionHandler = () => {

    let identifier = this.props.selectedSoftware._id
    let version = this.props.selectedSoftware.versionNumber

    axios.delete("/decoders/deleteVersion/" + identifier + "/" + version)
    .then(() => {
              
      // Close the modal
      this.props.closeModal()

    }).catch(err => {
      let message = err.toString()
      if (err.response) {
        if (typeof err.response.data === "object") {
          message = message + "\r\n" + JSON.stringify(err.response.data)
        }
      }
      alert(message)
    })
  }

  uploadFile = (event) => {
    this.setState({ chosenFile: event.target.files[0] })
  }


  render() {

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h3>{this.props.addMode ? "Add decoder version" : "Edit decoder version"}</h3>
        <InputRow
          title="Software component"
          enabled={false}
          ultrawide
          firstRow
          value={this.state.softwareComponent ?? ""}/>
        <InputRow
          title="Version number"
          enabled
          ultrawide
          value={this.state.versionNumber ?? ""}
          placeholder={"E.g. 1.0.2"}
          onChange={(event) => this.setState({ versionNumber: event.target.value, modified: true })}/>
        <InputRow
          title="Release date"
          enabled
          ultrawide
          value={this.state.releaseDate ?? ""}
          placeholder={"E.g. January 14th 2022"}
          onChange={(event) => this.setState({ releaseDate: event.target.value, modified: true })}/>
        <InputRow
          title="Update description"
          enabled
          ultrawide
          value={this.state.changelogMessage ?? ""}
          placeholder={"Changelog message"}
          onChange={(event) => this.setState({ changelogMessage: event.target.value, modified: true })}/>

          {this.props.addMode &&
          <FileRow
            title="Decoder package"
            body="Upload .deb file"
            extension=".deb" onClick={this.uploadFile}/>}

        <div className={classes.buttonRow}>
          {!this.props.addMode && <button className={classes.deleteButton} onClick={this.deleteVersionHandler}>Delete</button>}
          <button className={this.state.modified ? classes.updateButton : classes.closeButton} onClick={this.state.modified ? this.saveSoftwareToServer : this.props.closeModal}>{this.props.addMode ? "Upload" : this.state.modified ? "Update" : "Close"}</button>
        </div>
      </Modal>
    );
  }
}

export default SoftwareModal;
