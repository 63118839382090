import React, { Component } from "react"
import Modal from "../../../../containers/UI/Modal/Modal";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow"
import classes from "./FilterModal.css"


class FilterModal extends Component {

  state = {
    part: "all-parts",
  }
  allParts = { label: "All parts", value: "all-parts" }

  componentDidMount() {
    if (this.props.filteredPart !== "") {
      this.setState({ part: this.props.filteredPart })
    }
  }

  
  render() {

    return (
      <Modal shouldShow={this.props.shouldShow}>
        
        <div className={classes.titleContainer}>
          <p>Filter stock bookings</p>
        </div>

        <SelectRow
          title="Show bookings for"
          firstRow
          ultrawide
          defaultValue={"all-parts"}
          value={this.state.part}
          onChange={(newValue) => this.setState({ part: newValue.value })}
          options={[this.allParts, ...this.props.partOptions]}/>

        <div className={classes.buttonContainer}>
          <button className={classes.cancelButton} onClick={this.props.closeModal}>Cancel</button>
          <button className={classes.exportButton} onClick={() => { this.props.applyFilter(this.state.part); this.props.closeModal() }}>Apply</button>
        </div>
      </Modal>
    )
  }
}


export default FilterModal;
