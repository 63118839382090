import React from "react";
import ContentBox from "../../../../containers/UI/ContentBox/ContentBox";
import InfoRow from "../InfoRow/InfoRow";

const hardwareInfo = (props) => {

  let os = "";
  let uptime = "";
  let ip4Address = "";
  let ip6Address = "";

  if (props.os) {
    os = props.os.distro + " " + props.os.release;
    uptime = secondsToString(props.time.uptime);
    ip4Address = props.interface.ip4;
    ip6Address = props.interface.ip6;
  }

  return (
    <ContentBox title="System information">
      <div style={{display: "flex", "flexFlow": "column"}}>
        <InfoRow title="Operating system" body={os} firstRow={true}/>
        <InfoRow title="System uptime" body={uptime}/>
        <InfoRow title="IPv4 address" body={ip4Address}/>
        <InfoRow title="IPv6 address" body={ip6Address}/>
      </div>
    </ContentBox>
  );
}

function secondsToString(uptime) {

    const years = Math.floor( uptime / 31536000 )
    const days = Math.floor( ( uptime %= 31536000 ) / 86400 )
    const hours = Math.floor( ( uptime %= 86400 ) / 3600 )
    const minutes = Math.floor( ( uptime %= 3600 ) / 60 )
    const seconds = uptime % 60;

    if ( days || hours || seconds || minutes ) {
      return ( years ? years + "y " : "" ) +
      ( days ? days + "d " : "" ) +
      ( hours ? hours + "h " : ""  ) +
      ( minutes ? minutes + "m " : "" ) +
      Number.parseFloat( seconds ).toFixed(0) + "s";
    }
    return "< 1s";
}


export default hardwareInfo;
