import React from "react";
import classes from "./FileRow.css"


const fileRow = (props) => {

  const outerClasses = [classes.rowContainer];
  if (props.firstRow === true) {
    outerClasses.push(classes.isFirstRow);
  }
  return (
    <div className={outerClasses.join(" ")}>
      <p className={classes.title}>{props.title}</p>
      <input
        type="file"
        id="fileInput"
        accept={props.extension}
        className={classes.fileInput}
        onChange={props.onClick}/>
      <label htmlFor="fileInput" className={classes.fileLabel}>{props.body}</label>
    </div>
  );
};


export default fileRow;
