import React from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InfoRow from "../../../configuration/shared/InfoRow/InfoRow";
import classes from "./StatisticsModal.css"


const StatisticsModal = (props) => {

  function secondsToString(uptime) {
      const years = Math.floor( uptime / 31536000 )
      const days = Math.floor( ( uptime %= 31536000 ) / 86400 )
      const hours = Math.floor( ( uptime %= 86400 ) / 3600 )
      const minutes = Math.floor( ( uptime %= 3600 ) / 60 )
      const seconds = uptime % 60;
      if ( days || hours || seconds || minutes ) {
        return ( years ? years + "y " : "" ) +
        ( days ? days + "d " : "" ) +
        ( hours ? hours + "h " : ""  ) +
        ( minutes ? minutes + "m " : "" ) +
        Number.parseFloat( seconds ).toFixed(0) + "s";
      }
      return "< 1s";
  }

  return (
    <Modal shouldShow={props.shouldShow} bigmodal>
      <div className={classes.headerContainer}>
        <h2 className={classes.title}>{props.gateway.trivialName}</h2>
        <p className={classes.lastUpdate}>Last updated: {(new Date((props.gateway.lastOnline ?? 0) * 1000)).toLocaleString("nl-BE")}</p>
      </div>


      <div className={classes.softwareContainer}>
        <div className={classes.softwareColumn}>
          <InfoRow
            title="NodeRED"
            success={props.gateway.nodeRedRunning}
            body={"nodeRedRunning" in props.gateway ? (props.gateway.nodeRedRunning ? "Running" : "Not running") : ""}
            firstRow/>
          <InfoRow
            title="Mosquitto"
            success={props.gateway.mosquittoRunning}
            body={"mosquittoRunning" in props.gateway ? (props.gateway.mosquittoRunning ? "Running" : "Not running") : ""}/>
        </div>
        <div className={classes.softwareColumn}>
          <InfoRow
            title="LoRaWAN server"
            success={props.gateway.applicationServerRunning}
            body={"applicationServerRunning" in props.gateway ? (props.gateway.applicationServerRunning ? "Running" : "Not running") : ""}
            firstRow/>
          <InfoRow
            title="Packet forwarder"
            success={props.gateway.packetForwarderRunning}
            body={"packetForwarderRunning" in props.gateway ? (props.gateway.packetForwarderRunning ? "Running" : "Not running") : ""}/>
        </div>
      </div>
      <hr />
      <div className={classes.softwareContainer}>
        <div className={classes.softwareColumn}>
          <InfoRow
            title="Date added"
            body={"provisioningDate" in props.gateway ? (new Date((props.gateway.provisioningDate ?? 0) * 1000)).toLocaleString("nl-BE") : ""}
            firstRow/>
          <InfoRow
            title="First online"
            body={"firstOnline" in props.gateway ? (new Date((props.gateway.firstOnline ?? 0) * 1000)).toLocaleString("nl-BE") : ""}/>
          <InfoRow
            title="Last online"
            body={"lastOnline" in props.gateway ? props.timeDifferenceString(props.gateway.lastOnline) : ""}/>
        </div>
        <div className={classes.softwareColumn}>
          <InfoRow
            title="Device time"
            body={"deviceTime" in props.gateway ? (new Date((props.gateway.deviceTime ?? 0) * 1000)).toLocaleString("nl-BE") : ""}
            firstRow/>
          <InfoRow
            title="Device uptime"
            body={"deviceUptime" in props.gateway ? (secondsToString(props.gateway.deviceUptime)) : ""}/>
          <InfoRow
          title="Vendor"
          body={props.gateway.vendor}/>
        </div>
      </div>

      <hr />
      <div className={classes.softwareContainer}>
        <div className={classes.softwareColumn}>
          <InfoRow title="LoRa device count"
            body={props.gateway.provisionedDevicesCount} firstRow/>
        </div>
        <div className={classes.softwareColumn}>
          <InfoRow
            title="Decoder version"
            body={props.gateway.currentDecoderVersion ? "Version " + props.gateway.currentDecoderVersion : ""}
            firstRow/>
        </div>
      </div>
      <div className={classes.softwareContainer}>
        <div className={classes.softwareColumn}>
          <InfoRow title="Forwarder count"
            body={props.gateway.forwarderCount}/>
        </div>
        <div className={classes.softwareColumn}>
          <InfoRow 
            title="Build number"
            body={props.gateway.versionString ? "Version " + props.gateway.versionString : ""}/>
        </div>
      </div>
      <div className={classes.softwareContainer}>
        <div className={classes.softwareColumn}>
          <InfoRow title="License"
            success={props.gateway.alloradoLicenseValid ? null : false}
            body={props.gateway.alloradoLicenseMessage ? ((props.gateway.isForwarderSlave ? "As forwarder - " : "As master - ") + props.gateway.alloradoLicenseMessage.toLowerCase()) : ""}/>
        </div>
        <div className={classes.softwareColumn}>
          <InfoRow 
            title="Intervention"
            body={props.gateway.intervention ? (props.gateway.intervention.charAt(0).toUpperCase() + props.gateway.intervention.slice(1)) : ""}/>
        </div>
      </div>


      <button className={classes.closeButton} onClick={() => props.closeModal()}>Close</button>
    </Modal>
  );
}


export default StatisticsModal;
