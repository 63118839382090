import React from "react"
import classes from "./NavigationHeader.css"
import Logo from "./AlloradoLogo.png"

const navigationHeader = (props) => {

  const logoutSection = (
    <div>
      <label className={classes.email}>{props.username}</label>
      <button onClick={props.logout} className={classes.logout}>Log out</button>
    </div>
  )


  return(
    <div className={props.isAuthenticated ? classes.authenticatedContainer : classes.unauthenticatedContainer}>
      <div>
        <div className={classes.leftContainer}>
          <img className={classes.logo} src={Logo} alt="Allorado Gateway logo"/>
          <h2 className={classes.console}>Console</h2>
        </div>
      </div>
      {props.isAuthenticated ? logoutSection : null}
    </div>
  );
}

export default navigationHeader;
