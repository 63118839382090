import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import classes from "./CustomerModal.css"
import axios from "../../../../axios";


class GatewayModal extends Component {

  state = {
    customer: {}
  }

  componentDidMount() {
    this.setState({ ...this.props.selectedCustomer })
  }

  saveCustomerToServer = () => {
    // Sync to the backend
    axios.post("/customers/update", this.state);
    // Close the modal view
    this.props.closeModal()
  }


  render() {

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h3>Edit customer</h3>
        <InputRow
          title="Customer name"
          enabled
          ultrawide
          firstRow
          value={this.state.name ?? ""}
          placeholder={"E.g. Siemens AG"}
          onChange={(event) => this.setState({ name: event.target.value })}/>
        <InputRow
          title="Representative"
          enabled
          ultrawide
          value={this.state.representative ?? ""}
          placeholder={"Main contact"}
          onChange={(event) => this.setState({ representative: event.target.value })}/>
        <InputRow
          title="VAT number"
          enabled
          ultrawide
          value={this.state.vatNumber ?? ""}
          placeholder={"VAT number"}
          onChange={(event) => this.setState({ vatNumber: event.target.value })}/>
        <InputRow
          title="Address line 1"
          enabled
          ultrawide
          value={this.state.addressLine1 ?? ""}
          placeholder={"Line 1"}
          onChange={(event) => this.setState({ addressLine1: event.target.value })}/>
        <InputRow
          title="Address line 2"
          enabled
          ultrawide
          value={this.state.addressLine2 ?? ""}
          placeholder={"Line 2"}
          onChange={(event) => this.setState({ addressLine2: event.target.value })}/>
        <InputRow
          title="Country"
          enabled
          ultrawide
          value={this.state.country ?? ""}
          placeholder={"Country"}
          onChange={(event) => this.setState({ country: event.target.value })}/>

        <button className={classes.closeButton} onClick={this.saveCustomerToServer}>Save</button>
      </Modal>
    );
  }
}

export default GatewayModal;
