import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./cashout.css"
import CustomerModal from "./CashoutModal/CashoutModal"

const columns = [
  { name: "Datum", selector: "date", sortable: true, style: { fontSize: "15px" }, format: (row) => readableDateForEpoch(row.date) },
  { name: "Beschrijving", selector: "description", sortable: false, left: true, style: { fontSize: "14px" }},
  { name: "Ontvanger", selector: "recipient", sortable: true, right: true, style: { fontSize: "14px" }, format: (row) => capitalize(row.recipient) },
  { name: "Bedrag", selector: "amount", sortable: true, right: true, style: { fontSize: "14px" }, format: (row) => formatAmount(row.amount) },
]

class Customers extends Component {

  state = {
    cashouts: [],
    selectedCashout: {},
    showCashoutModal: false
  }


  componentDidMount() {
    axios.get("/cashout/all")
      .then(response => {
        if (response.data) {
          let totals = calculateTotals(response.data)
          this.setState({ cashouts: response.data, bram: totals.bram, filip: totals.filip })
        }
      })
  }

  displayModal = (shouldBeShown, cashout) => {
    this.setState({ showCashoutModal: shouldBeShown, selectedCashout: cashout })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(() => {
        axios.get("/cashout/all").then(response => {
          if (response.data) {
            let totals = calculateTotals(response.data)
            this.setState({ cashouts: response.data, bram: totals.bram, filip: totals.filip })
          }
        })
      }, 500)
    }
  }


  render() {

    return (
      <ContentContainer title="Uitbetaling">

        <div className={classes.highlightContainer}>
          <div className={classes.highlightTile}>
            <label>{this.state.bram !== undefined ? "€ " + this.state.bram.toLocaleString("de-DE") : ""}</label>
            <label>Uitbetaald aan Bram</label>
          </div>
          <div className={classes.highlightTile}>
            <label>{this.state.filip !== undefined ? "€ " + this.state.filip.toLocaleString("de-DE") : ""}</label>
            <label>Uitbetaald aan Filip</label>
          </div>
        </div>

        <button onClick={() => this.displayModal(true, null)} className={classes.addButton}>Add cashout</button>
        <DataTable
          columns={columns}
          data={this.state.cashouts}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"date"}
          defaultSortAsc={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold", fontSize: "15px" }}
          }}
          onRowClicked={(customer) => this.displayModal(true, customer)}
        />


        {this.state.showCashoutModal && <>
        <CustomerModal
          shouldShow={this.state.showCashoutModal}
          selectedCashout={this.state.selectedCashout}
          closeModal={() => this.displayModal(false, {})}/>
        </>}

      </ContentContainer>
    );
  }
}


function calculateTotals(cashouts) {
  let totals = { bram: 0, filip: 0}

  cashouts.forEach((cashout) => {
    totals[cashout.recipient] = totals[cashout.recipient] + cashout.amount
  })

  return totals
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function readableDateForEpoch(epoch) {
  console.log(epoch)
  let date = new Date(epoch * 1000)
  return String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, '0') + "/" + date.getFullYear()
}

function formatAmount(amount) {
  return "€ " + parseFloat(amount).toLocaleString("de-DE")
}

export default Customers;
