import React from "react";
import ContentBox from "../../../../containers/UI/ContentBox/ContentBox";
import InfoRow from "../InfoRow/InfoRow";

const processor = (props) => {

  let avgLoad = ""
  let currentLoad = ""
  let currentSys = ""
  let currentUser = ""

  if (props.load) {
    avgLoad = (props.load.avgload * 100).toFixed(0);
    currentLoad = props.load.currentload.toFixed(2);
    currentSys = props.load.currentload_system.toFixed(2);
    currentUser = props.load.currentload_user.toFixed(2);
  }

  return (
    <ContentBox title="Proccessor">
      <div style={{display: "flex", "flexFlow": "column"}}>
        <InfoRow
          title="Average load"
          body={avgLoad + "%"} firstRow={true}/>
        <InfoRow
          title="Current load"
          body={currentLoad + "%"}/>
        <InfoRow
          title="Current user load"
          body={currentUser + "%"}/>
        <InfoRow
          title="Current system load"
          body={currentSys + "%"}/>
      </div>
    </ContentBox>
  );
}

export default processor;
