import React, { Component } from "react"
import Modal from "../../../../containers/UI/Modal/Modal";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow"
import classes from "./FilterModal.css"


class FilterModal extends Component {

  state = {
    category: "all-categories",
  }
  allCategories = { label: "All categories", value: "all-categories" }

  componentDidMount() {
    if (this.props.filteredCategory !== "") {
      this.setState({ category: this.props.filteredCategory })
    }
  }

  
  render() {

    return (
      <Modal shouldShow={this.props.shouldShow}>
        
        <div className={classes.titleContainer}>
          <p>Filter stock overview</p>
        </div>

        <SelectRow
          title="Show stock for"
          firstRow
          ultrawide
          defaultValue={"all-categories"}
          value={this.state.category}
          onChange={(newValue) => this.setState({ category: newValue.value })}
          options={[this.allCategories, ...this.props.categoryOptions]}/>

        <div className={classes.buttonContainer}>
          <button className={classes.cancelButton} onClick={this.props.closeModal}>Cancel</button>
          <button className={classes.exportButton} onClick={() => { this.props.applyFilter(this.state.category); this.props.closeModal() }}>Apply</button>
        </div>
      </Modal>
    )
  }
}


export default FilterModal;
