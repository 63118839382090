import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow";
import classes from "./PartModal.css"
import axios from "../../../../axios";


class ItemModal extends Component {

  state = {
    item: {},
    valuesChanged: false,
  }

  componentDidMount() {
    if (this.props.selectedItem !== null) {
      this.setState({ item: this.props.selectedItem })
    }
  }


  // MARK: - Handlers

  saveHandler = () => {
    // Sync to the backend
    if (this.state.valuesChanged) {
      axios.post("/parts/update-use", this.state.item)
    }
    // Close the modal view
    this.props.closeModal()
  }

  updateProperty(property, value) {
    let item = {...this.state.item}
    item[property] = value
    this.setState({ item, valuesChanged: true })
  }


  // MARK: - User interface

  render() {

    const createMode = (this.props.selectedItem === null)

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h2>{createMode ? "Nieuw gebruik toevoegen" : "Gebruik aanpassen"}</h2>
        
        { createMode ? 
        <SelectRow
        title="Onderdeel"
        ultrawide
        increasedPadding
        firstRow
        value={this.state.item ? this.state.item._id : ""}
        onChange={(newValue) => this.updateProperty("_id", newValue.value )}
        options={this.props.partOptions}/>
        :
        <InputRow
          title="Onderdeel"
          ultrawide
          firstRow
          value={this.state.item.name ?? ""}
          onChange={(event) => this.updateProperty("name", event.target.value)}/>
        }

        <InputRow
          title="Aantal stuks in gebruik"
          enabled
          ultrawide
          value={this.state.item.unitsInUse ?? ""}
          placeholder={"E.g. 3"}
          onChange={(event) => this.updateProperty("unitsInUse", event.target.value)}/>

        <textarea
            className={classes.notesField}
            value={this.state.item.usageDescription}
            type="text"
            placeholder="Beschrijving van het gebruik"
            onChange={(event) => this.updateProperty("usageDescription", event.target.value)}>{this.state.item.usageDescription}
        </textarea>
  
        <div className={classes.buttonRow}>
          <button className={(this.state.valuesChanged || createMode) ? classes.saveButton : classes.closeButton} onClick={this.saveHandler}>{(this.state.valuesChanged || createMode) ? "Save" : "Close"}</button>
        </div>
      </Modal>
    );
  }
}

export default ItemModal;
