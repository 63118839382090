import React from "react";
import classes from "./SelectRow.css"
import Select from "./Select/Select"

const infoRow = (props) => {

  const outerClasses = [classes.rowContainer];
  const selectClasses = [classes.dropdown];

  if (props.firstRow === true) {
    outerClasses.push(classes.isFirstRow);
  }
  if (props.reducedWidth === true) {
    selectClasses.push(classes.reducedWidth);
  }
  if (props.increasedPadding === true) {
    selectClasses.push(classes.increasedPadding);
  }
  if (props.ultrawide === true) {
    selectClasses.push(classes.ultrawide);
  }

  return (
    <div className={outerClasses.join(" ")}>
      <p className={classes.title}>{props.title}</p>
      <Select
        className={selectClasses.join(" ")}
        options={props.options}
        onChange={props.onChange}
        value={props.value}/>
    </div>
  );
};


export default infoRow;
